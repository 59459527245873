import { mdiChartLine, mdiAccountGroup, mdiStarHalfFull, mdiPodium, mdiDotsHorizontal, mdiCalendarMonth, mdiAccountPlus, mdiGroup } from '@mdi/js';
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";


function GroupFooter({ groupId }) {
    const navigate = useNavigate();

    return (
        <>
            <div className="bottom-expanded-menu is-hidden" id="groupFooterMoreMenu">
                <div className="columns is-vcentered ml-5">
                   <div className="column" onClick={() => { navigate(`/groupSessions/${groupId}`) }}>
                        <div className="is-primary has-background-primary is-div-rounded image is-48x48 has-text-centered ml-3">
                            <Icon path={mdiCalendarMonth} size={1.25} color="white" />
                        </div>
                        <div className="is-size-7 has-text-weight-semibold pt-2 no-wrap has-background-light has-text-centered">
                            Sessions
                        </div>
                    </div>
                    <div className="column" onClick={() => { navigate(`/groupMembers/${groupId}`) }}>
                        <div className="is-primary has-background-primary is-div-rounded image is-48x48 has-text-centered ml-3">
                            <Icon path={mdiAccountGroup} size={1.25} color="white" />
                        </div>
                        <div className="is-size-7 has-text-weight-semibold pt-2 no-wrap has-background-light has-text-centered">
                            Members
                        </div>
                    </div>
                    <div className="column" onClick={() => { navigate(`/gameStats/${groupId}`) }}>
                        <div className="is-primary has-background-primary is-div-rounded image is-48x48 has-text-centered ml-3">
                            <Icon path={mdiChartLine} size={1.25} color="white" />
                        </div>
                        <div className="is-size-7 has-text-weight-semibold pt-2 no-wrap has-background-light has-text-centered">
                            Game Stats
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar is-fixed-bottom has-background-primary is-hidden-desktop mt-1 pb-1 mb-0" role="navigation">
                <div className="navbar-brand">
                    <a className="navbar-item is-expanded is-block has-text-centered" onClick={() => { navigate(`/group/${groupId}`) }}>
                        <Icon path={mdiAccountGroup} size={1} color="white" />
                        <div className="has-text-white">
                            <p className="is-size-7">Group Home</p>
                        </div>
                    </a>
                    <a className="navbar-item is-expanded is-block has-text-centered" onClick={() => { navigate(`/logGame/${groupId}`) }}>
                        <Icon path={mdiPodium} size={1} color="white" />
                        <div className="has-text-white">
                            <p className="is-size-7">Log Game</p>
                        </div>
                    </a>
                    <a className="navbar-item is-expanded is-block has-text-centered" onClick={() => { navigate(`/rateGames/${groupId}`) }}>
                        <Icon path={mdiStarHalfFull} size={1} color="white" />
                        <div className="has-text-white">
                            <p className="is-size-7">Rate</p>
                        </div>
                    </a>
                    <a className="navbar-item is-expanded is-block has-text-centered" onClick={() => { document.getElementById("groupFooterMoreMenu").classList.toggle("is-hidden")}}>
                        <Icon path={mdiDotsHorizontal} size={1} color="white" />
                        <div className="has-text-white">
                            <p className="is-size-7">More</p>
                        </div>
                    </a>
                </div>
            </nav>
        </>
    );
}

export default GroupFooter;