import React, { useState, useEffect } from "react";
import { firebaseObserver } from "../utils/firebase";
import NotSignedIn from "./NotSignedIn";
import { _tryJoinGroup, _getUnclaimedMembers, _claimMember } from "../data/groups";
import { useNavigate } from "react-router-dom";
import { _isUserInGroup } from "../data/users";

function JoinGroup({ groupId }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasJoinedGroup, setHasJoinedGroup] = useState(false);
    const [unclaimedMembers, setUnclaimedMembers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', isUserLoggedIn => {
            setIsLoggedIn(isUserLoggedIn);
            if (isUserLoggedIn) {
                _isUserInGroup(groupId).then((isInGroup) => {
                    if (isInGroup) {
                        navigate(`/group/${groupId}`);
                    }

                    setIsLoading(false);
                });
            } else {
                setIsLoading(false);
            }
        });
    }, []);

    const tryJoinGroup = async () => {
        if (await _tryJoinGroup(groupId, +document.getElementById("groupInviteCode").value)) {
            var members = await _getUnclaimedMembers(groupId);
            setUnclaimedMembers(members);
            setHasJoinedGroup(true);
            //navigate(`/group/${groupId}`);
        } else {
            document.getElementById("inviteError").classList.remove("is-hidden");
        }
    }

    const claimMember = () => {
        var memberId = document.querySelector('input[name="pickMember"]:checked').value;
        if (memberId) {
            var displayName = localStorage.getItem("displayName");
            if (!displayName) {
                displayName = "";
            }
            _claimMember(groupId, memberId, displayName);
        }

        navigate(`/group/${groupId}`);
    }

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : isLoggedIn && !hasJoinedGroup ? (
                <div className="container ml-2 mr-2">
                    <div className="columns">
                        <div className="column is-5 is-offset-2">
                            <div className="title">
                                Join Group
                            </div>
                            <p>You've been invited to join a board game group. <br />
                                Simply enter your group invite code and hit the button!</p>
                            <input id="groupInviteCode" className="input" type="text" placeholder="Group Invite Code" />
                            <button className="button has-background-success-dark has-text-light" onClick={() => tryJoinGroup()}>Join Group</button>
                            <div className="is-hidden has-text-danger has-text-weight-bold" id="inviteError">Looks like something went wrong. Make sure the Invite code is right or try again later</div>
                        </div>
                    </div>
                </div>
            ) : isLoggedIn && hasJoinedGroup ? (
                <div className="container ml-2 mr-2">
                    Is one of these unclaimed members you? <span className="has-text-weight-bold">Choosing a user cannot be undone and the name will change to match your display name.</span>
                    <ul>
                        {unclaimedMembers.map((member, index) => (
                           <li key={index}><input type="radio" name="pickMember" value={member.memberId}/> {member.displayName}</li>
                        ))}
                            <li><input type="radio" name="pickMember" value=""/> None, I'm my own person</li>
                    </ul>
                    <button className="button has-background-success-dark has-text-light" onClick={() =>{ claimMember(); }}>Continue</button>
                </div>
            ) : 
            (
                <NotSignedIn />
            )}
        </div>
    );
}

export default JoinGroup;