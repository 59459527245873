import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import { _getGamesPlayed, _getGameStats } from "../data/groups";
import { _getGroupMembersFromCache } from "../data/localCache";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function GameStatsForGroup({ groupId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [gamesPlayed, setGamesPlayed] = useState({});
    const [gameStats, setGameStats] = useState({});
    const [groupMembers, setGroupMembers] = useState([]);

    const navigate = useNavigate();
    const getPageData = async () => {
        await Promise.all([_getGamesPlayed(groupId), _getGroupMembersFromCache(groupId)]).then(([gamesPlayed, members]) => {
            setGamesPlayed(gamesPlayed);
            setGroupMembers(members);
            setIsLoading(false);
        });
    }

    const getGameData = async (game) => {
        setDataLoading(true);
        await _getGameStats(groupId, game).then((gameStats) => {
            setGameStats(gameStats);
            setDataLoading(false);
        });
    }

    useEffect(() => {
        getPageData();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="mr-auto ml-auto">
                <div className="is-size-5 has-text-weight-semibold">Game Stats</div>
            </div>
            <div className="mr-auto ml-auto is-fullwidth">
                <div className="field is-horizontal">
                    <div className="field-label is-normal no-wrap is-hidden-mobile">
                        <label className="label">Choose Game</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-narrow">
                            <div className="control">
                                <div className="select is-fullwidth is-rounded is-primary">
                                    <select onChange={(event) => { getGameData(event.currentTarget.value); }}>
                                        <option value='-1'>Select a Game</option>
                                        {Object.keys(gamesPlayed).sort().map((game) => {
                                            return <option key={game} value={game}>{game}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {dataLoading && <Loading />}

            {!dataLoading && <>
                <div className="columns">
                    {gameStats.coopHighscores?.length > 0 &&
                        <div className="column is-3">
                            <div className='box has-background-info-95 has-wrap-anywhere'>
                                <div className="is-size-6 has-text-weight-semibold has-text-centered">
                                    <span className="mr-3 is-size-5">High Cooperative Scores</span>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered m-0">
                                    <div className="column is-3 is-offset-3 p-1">Score</div>
                                    <div className="column is-3 p-1 has-text-centered">Date</div>
                                </div>
                                {gameStats.coopHighscores.sort((a, b) => b.score - a.score || new Date(b.date) - new Date(a.date)).map((highScore, index) => {
                                    return (
                                        <div className="columns is-mobile is-size-7 m-0" key={"coopHighScore_" + index}>
                                            <div className="column is-offset-3 is-3 has-text-centered p-0">{highScore.score}</div>
                                            <div className="column is-4 has-text-centered p-0"><div className="is-clickable is-link" onClick={() => { navigate("/groupSession/" + groupId, { state: { sessionId: highScore.date } }) }}> {new Date(highScore.date).toLocaleDateString()}</div></div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    }

                    {gameStats.highscores?.length > 0 &&
                        <div className="column is-3 ">
                            <div className='box has-background-info-95 has-wrap-anywhere'>
                                <div className="is-size-6 has-text-weight-semibold has-text-centered mb-3">
                                    <span className="mr-3 is-size-5">Best Scores</span>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered">
                                    <div className="column is-3 p-1">Player</div>
                                    <div className="column is-3 p-1">Score</div>
                                    <div className="column is-3 p-1">Date</div>
                                </div>
                                {gameStats.highscores.sort((a, b) => b.score - a.score || new Date(b.date) - new Date(a.date)).map((highScore, index) => {
                                    return (
                                        <div className="columns is-mobile is-size-7">
                                            <div className="column is-3 p-1 ">{groupMembers[highScore.member].displayName}</div>
                                            <div className="column is-3 p-1 has-text-centered">{highScore.score}</div>
                                            <div className="column is-4 p-1 has-text-centered p-0">
                                                <div className="is-clickable is-link" onClick={() => { navigate("/groupSession/" + groupId, { state: { sessionId: highScore.date } }) }}> {new Date(highScore.date).toLocaleDateString()}</div>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    }

                    {gameStats.winners &&
                        <div className="column is-3 ">
                            <div className='box has-background-info-95 has-wrap-anywhere'>
                                <div className="is-size-6 has-text-weight-semibold has-text-centered mb-3">
                                    <span className="mr-3 is-size-5">Most Wins</span>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered">
                                    <div className="column is-offset-3 is-3 p-1">Player</div>
                                    <div className="column is-3 p-1">Count</div>
                                </div>
                                {Object.entries(gameStats.winners).sort((a, b) => b[1].length - a[1].length).map(([memberId, wins]) => {
                                    return (
                                        <div className="columns is-mobile is-size-7">
                                            <div className="column is-offset-3 is-3 p-1 ">{groupMembers[memberId].displayName}</div>
                                            <div className="column is-3 p-1 has-text-centered">{wins.length}</div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    }

                    {gameStats.playerCounts &&
                        <div className="column is-3 ">
                            <div className='box has-background-info-95 has-wrap-anywhere'>
                                <div className="is-size-6 has-text-weight-semibold has-text-centered mb-3">
                                    <span className="mr-3 is-size-5">Most Plays</span>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered">
                                    <div className="column is-offset-3 is-3 p-1">Player</div>
                                    <div className="column is-3 p-1">Count</div>
                                </div>

                                {Object.entries(gameStats.playerCounts).sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0])).map(([memberId, count]) => {
                                    return (
                                        <div className="columns is-mobile is-size-7" key={"playerCounts_" + memberId}>
                                            <div className="column is-offset-3 is-3 p-1 ">{groupMembers[memberId].displayName}</div>
                                            <div className="column is-3 p-1 has-text-centered">{count}</div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    }

                    {gameStats.playerHighscores &&
                        <div className="column is-3 ">
                            <div className='box has-background-info-95 has-wrap-anywhere'>
                                <div className="is-size-6 has-text-weight-semibold has-text-centered mb-3">
                                    <span className="mr-3 is-size-5">Player Best Scores</span>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered">
                                    <div className="column is-offset-3 is-3 p-1">Player</div>
                                    <div className="column is-3 p-1">Score</div>
                                </div>
                                {Object.keys(gameStats.playerHighscores).sort((a, b) => gameStats.playerHighscores[b] - gameStats.playerHighscores[a]).map((member, index) => {
                                    return (
                                        <div className="columns is-mobile is-size-7" key={"playerHighScores_" + index}>
                                            <div className="column is-offset-3 is-3 p-1 ">{groupMembers[member].displayName}</div>
                                            <div className="column is-3 p-1 has-text-centered">{gameStats.playerHighscores[member]}</div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    }

                    {gameStats.coopSessions && 
                        <div className="column is-3 ">
                            <div className='box has-background-info-95 has-wrap-anywhere'>
                                <div className="is-size-6 has-text-weight-semibold has-text-centered mb-3">
                                    <span className="mr-3 is-size-5">Outcomes</span>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered">
                                    <div className="column is-offset-3 is-3 p-1">Wins</div>
                                    <div className="column is-3 p-1">
                                    {gameStats.coopSessions.filter(session => session.won === true).length}
                                    </div>
                                </div>
                                <div className="columns is-mobile has-text-weight-bold has-text-centered">
                                    <div className="column is-offset-3 is-3 p-1">Losses</div>
                                    <div className="column is-3 p-1">{gameStats.coopSessions.filter(session => session.won === false).length}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>}
        </>
    );
}

export default GameStatsForGroup;