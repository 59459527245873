import React from 'react';
import Logo24 from '../media/logo24.png';

function Loading() {
    return (
        <div className="container has-text-centered is-vcentered mt-5 is-justify-content-center is-flex">
            <figure className="image is-128x128">
                <img alt="Loading..." className="rotate mb-5 mt-5" width="50%" src={Logo24}></img>
            </figure>
        </div>
    );
}

export default Loading;