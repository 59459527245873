import React, { useState, useEffect } from 'react';
import AuthenticationButtons from '../components/AuthenticationButtons.js';
import Logo from '../media/logo.js';
import { firebaseObserver } from '../utils/firebase.js';
import { _saveUser } from '../data/users.js';

function SiteNav(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', isUserLoggedIn => {
            setIsLoggedIn(isUserLoggedIn);
            setIsLoading(false);
        });

        return () => { firebaseObserver.unsubscribe('authStateChanged'); }

    }, []);

    const toggleMenu = () => {
        document.getElementById("bgsNavbar").classList.toggle('is-active');
        document.getElementById("bgsBurgerMenu").classList.toggle('is-active');
    }

   
    return (
        <>
            <section className="">
                <div className="navbar is-fixed-top has-background-primary" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a href="/"><Logo className="" width={11} /></a>
                        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="bgsNavbar" id="bgsBurgerMenu" onClick={toggleMenu}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div className="navbar-menu" id="bgsNavbar">
                        <div className="navbar-end mr-2">
                            <div className="navbar-item">
                                <AuthenticationButtons toggleMenu={toggleMenu} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           
        </>
    );
}

export default SiteNav;