import React, { useState, useEffect } from "react";
import { _createGroup } from "../data/groups";
import { _getUserGroups } from "../data/users";
import { firebaseObserver, loggedIn } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { _saveUser } from '../data/users.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { toggleModal } from "../utils/uiUtils.js";

function MainPage() {
    const [groups, setGroups] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [displayName, setDisplayName] = useState();
    const [groupIcons, setGroupIcons] = useState({});
    const [newGroupName, setNewGroupName] = useState("");
    const navigate = useNavigate();
    const storage = getStorage();

    const changeDisplayName = () => {
        var newDisplayName = document.querySelector("#setDisplayNameMain > input").value;
        _saveUser({ displayName: newDisplayName }).then((result) => {
            setDisplayName(newDisplayName);
            localStorage.setItem("displayName", newDisplayName);
            document.getElementById("displayNameMain").classList.remove("is-hidden");
            document.getElementById("setDisplayNameMain").classList.add("is-hidden");
        });
    }

    const cancelChangedDisplayName = () => {
        document.getElementById("displayNameMain").classList.remove("is-hidden");
        document.getElementById("setDisplayNameMain").classList.add("is-hidden");
        document.querySelector("#setDisplayNameMain > input").value = displayName;
    }

    const createGameGroup = async () => {
        if (newGroupName === "") return;

        const groupId = await _createGroup(newGroupName);
        navigate('/group/' + groupId);
    };

    const init = async () => {
        sessionStorage.removeItem("groupIcon");
        sessionStorage.removeItem("groupName");
        if (loggedIn()) {
            getGroupIcon("addgroup").then((url) => {
                var icons = { ...groupIcons };
                icons["addgroup"] = url;
                setGroupIcons(icons);
            });
            setIsLoggedIn(true);
            setDisplayName(localStorage.getItem("displayName") || "Click to set");
            _getUserGroups().then((groups) => {
                setGroups(groups);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }

    const goToGroup = (groupId) => {
        sessionStorage.setItem("groupIcon", groupIcons[groupId]);
        navigate('/group/' + groupId);
    }

    useEffect(() => {
        if (groups) {
            const icons = {};
            const promises = Object.keys(groups).map((group) => {
                return getGroupIcon(groups[group].icon).then((url) => {
                    icons[group] = url;
                });
            });
            Promise.all(promises).then(() => {
                var tmpIcons = { ...groupIcons };
                setGroupIcons({ ...tmpIcons, ...icons });
            });
        }
    }, [groups]);

    const getGroupIcon = (icon) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `group_icons/light/${icon}.png`);
            getDownloadURL(storageRef).then((url) => {
                resolve(url);
            }).catch((error) => {
                resolve("generic_group_icon.png");
            });
        });
    }


    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', async (isUserLoggedIn) => {
            init();
        });

    }, []);

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <section className="container mt-5">
                <div className="mt-5">
                    <div className="columns">
                        <div className="column is-12-mobile is-hidden-desktop is-hidden-tablet">
                            {!isLoading &&
                                <div className="buttons pt-2 pb-0 mb-0">
                                    <h1 className={" mr-auto ml-auto" + (isLoggedIn ? "" : " is-hidden")} id="displayNameMain" onClick={() => {
                                        document.getElementById("displayNameMain").classList.add("is-hidden");
                                        document.getElementById("setDisplayNameMain").classList.remove("is-hidden");
                                    }} >
                                        Logged in as: <span className="has-text-weight-semibold is-underlined is-link is-clickable">{displayName}</span>
                                    </h1>
                                    <div id="setDisplayNameMain" className="is-hidden mr-auto ml-auto">
                                        <input type="text" defaultValue={displayName} className="input is-small" />
                                        <button className="button is-small is-warning mr-1 ml-1" onClick={() => changeDisplayName()}>Save</button>
                                        <button className="button is-small is-danger is-outlined mr-5" onClick={() => { cancelChangedDisplayName() }}>Cancel</button>
                                    </div>
                                </div>
                            }
                        </div>

                        {!groups &&
                            <div className="column is-3-desktop is-12-mobile">
                                <div className="card">
                                    <header className="card-header">
                                        <p className="card-header-title">
                                            Create New Group
                                        </p>
                                    </header>
                                    <div className="card-content pt-2">
                                        <div className="content">
                                            <div className="mb-2 is-size-7">
                                                Create a new group. Invite friends, track plays, ratings and stats.
                                            </div>
                                            <div className="field">
                                                <div className="control">
                                                    <input className="input" id="newGroupName" type="text" placeholder="Group Name" onChange={(e) => setNewGroupName(e.target.value)} />
                                                    <button className="button is-primary" onClick={() => { createGameGroup(); }}>Create</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {groups &&
                        <>
                            <div className="columns">
                                <div className="column is-12-desktop is-12-mobile">
                                    <div className="grid">
                                        {Object.keys(groups).sort((a, b) => { return groups[a].displayName.localeCompare(groups[b].displayName) }).map((group, index) => {
                                            var groupId = group;
                                            return (
                                                <div key={"group" + index} className="is-clickable cell" onClick={() => { goToGroup(groupId) }}>
                                                    <article className="box has-background-primary-80">
                                                        <figure className="image is-96x96 ml-auto mr-auto">
                                                            <img src={groupIcons[groupId]} alt={groups[group].icon} className="is-clickable" />
                                                        </figure>
                                                        <p className="subtitle has-text-centered">{groups[groupId].displayName}</p>
                                                    </article>
                                                </div>
                                            );
                                        })}
                                        <div className="is-clickable cell" onClick={() => { toggleModal("modal-addGroup") }}>
                                            <article className="box has-background-primary-80">
                                                <figure className="image is-96x96 ml-auto mr-auto">
                                                    <img src={groupIcons["addgroup"]} alt="Add new group" className="is-clickable" />
                                                </figure>
                                                <p className="subtitle has-text-centered mt-1">Add Group</p>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="modal-addGroup" className="modal pl-3 pr-5">
                                <div className="modal-background"></div>
                                <div className="modal-card">
                                    <header className="modal-card-head has-text-centered">
                                        <p className="modal-card-title">Add New Group</p>
                                        <button className="delete" aria-label="close" onClick={() => { toggleModal('modal-addGroup') }}></button>
                                    </header>
                                    <section className="modal-card-body">
                                        <div className="is-size-6">
                                            <div className="field">
                                                <label className="label">Enter Group Name</label>
                                                <div className="control">
                                                    <input className="input" id="groupName" type="text" placeholder="Text input" onChange={(e) => setNewGroupName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <footer className="modal-card-foot">
                                            <button className="button is-primary mr-5" onClick={() => { createGameGroup(); }}>Create Group</button>                                       
                                            <button className="button ml-5" aria-label="close" onClick={() => { toggleModal('modal-addGroup') }}>Close</button>
                                    </footer>
                                </div>
                            </div>

                        </>
                    }
                </div>
            </section>
        </>
    );
}

export default MainPage;