import React, { useState, useEffect } from "react";
import Icon from '@mdi/react';
import { mdiInformationSlabCircleOutline, mdiTrashCanOutline } from '@mdi/js'
import { toggleModal } from "../utils/uiUtils";
import { _getGroupMembers, _saveNewGroupMember, _toggleInactiveUser } from "../data/groups";
import { db, firebaseObserver, auth } from "../utils/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { _setMemberCache, _getGroupMembersFromCache, _updateMemberInCache } from "../data/localCache";
import Loading from "./Loading";

export const GroupMembers = ({ groupId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [groupMembers, setGroupMembers] = useState();
    const [groupAdmins, setGroupAdmins] = useState();
    const [groupOwner, setGroupOwner] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [displayMembers, setDisplayMembers] = useState();

    const init = async () => {
        await _getGroupMembers(groupId).then((members) => {
            setGroupMembers(members.members);
            setDisplayMembers(Object.keys(members.members).filter(memberId => !members.members[memberId].inactive).reduce((obj, memberId) => {
                obj[memberId] = members.members[memberId];
                return obj;
            }, {}));

            setGroupAdmins(members.admins);
            setGroupOwner(members.owner);
            setIsAdmin(members.admins.includes(auth.currentUser.uid));
            setIsLoading(false);
        });
    };

    const toggleInactiveUsers = async (showInactive) => {
        if (showInactive) {
            setDisplayMembers(groupMembers);
        } else {
            setDisplayMembers(Object.keys(groupMembers).filter(memberId => !groupMembers[memberId].inactive).reduce((obj, memberId) => {
                obj[memberId] = groupMembers[memberId];
                return obj;
            }, {}));
        }
    }

    const toggleInactiveUser = async (memberId, active) => {
        _toggleInactiveUser(groupId, memberId, active);
    }


    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', async (isUserLoggedIn) => {
            setIsAdmin(groupAdmins?.includes(auth.currentUser.uid));
        });
    }, []);


    useEffect(() => {
        init();
    }, []);

    const memberRef = collection(db, 'groups', groupId, 'members');

    useEffect(() => {
        const trackMembers = onSnapshot(memberRef, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                _updateMemberInCache(groupId, change.doc.id, change.doc.data(), change.type);
            });

            _getGroupMembersFromCache(groupId).then((members) => {
                setGroupMembers(members);
                if (document.getElementById("showInactive")?.checked) {
                    setDisplayMembers(members);
                } else {
                    setDisplayMembers(Object.keys(members).filter(memberId => !members[memberId].inactive).reduce((obj, memberId) => {
                        obj[memberId] = members[memberId];
                        return obj;
                    }, {}));
                }

            });

        });

        return () => {
            trackMembers(); // Unsubscribe from the snapshot listener when the component unmounts
        };
    }, []);

    if (isLoading) {
        return <Loading />
    }


    return (
        <>
            {/* Use a Card with a heard instead with materialize menu up and menu down icons  */}
            <header className="has-text-centered mb-4">
                <p className="subtitle">Group Members</p>
            </header>
            <div className="has-text-centered is-fullwidth">
                <AddMember addMember={_saveNewGroupMember} groupId={groupId} buttonClass="is-primary is-rounded is-small is-outlined mr-5" />
            </div>
            <div className="has-text-centered is-fullwidth">
                <input type="checkbox" id="showInactive" className="mr-2 mt-5 mb-0" onClick={(e) => toggleInactiveUsers(e.currentTarget.checked)} /> Show Inactive Members
            </div>
            <div className="box has-background-info-95 has-wrap-anywhere mt-2">
                <div className="columns is-mobile mb-0">
                    <div className="column is-6 has-text-weight-semibold">Name</div>
                    <div className={"column is-3 has-text-weight-semibold has-text-centered " + (isAdmin ? "" : "is-hidden")}>Inactive</div>
                    <div className={"column is-3 has-text-weight-semibold has-text-centered " + (isAdmin ? "" : "is-hidden")}>Remove</div>
                </div>
                {displayMembers && Object.keys(displayMembers).sort((a, b) => { return displayMembers[a].displayName.localeCompare(displayMembers[b].displayName) }).map((memberId, index) => {
                    return (
                        <div key={"memberList_" + index} className="columns is-mobile mb-0">
                            <div className="column is-6">
                                {groupMembers[memberId].displayName}
                            </div>
                            <div className={"column is-3 has-text-centered " + (isAdmin ? "" : "is-hidden")}>
                                <input type="checkbox" className={(groupMembers[memberId].isOwner ? "is-hidden" : "")} checked={groupMembers[memberId].inactive} onChange={(event) => { toggleInactiveUser(memberId, event.currentTarget.checked) }} />
                            </div>
                            <div className={"column is-3 has-text-centered " + (isAdmin ? "" : "is-hidden")}>
                                <Icon path={mdiTrashCanOutline} className={(groupMembers[memberId].isOwner ? "is-hidden" : "")} size={1} color="black" onClick={() => { console.log("Remove Member") }} />
                            </div>
                        </div>
                    )
                })}
            </div>

            <div id="modal-groupMemberInfo" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Group Members</p>
                        <button className="delete" aria-label="close" onClick={() => { toggleModal('modal-groupMemberInfo') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="is-size-6">
                            <li>The member list is used primarily to pre-fill the names for the games you log</li>
                            <li>Any member of the group can add a member </li>
                            <li>Members can be removed by only by an admin of the group</li>
                            <li>When you send the ivite link to members and they join they can link their name to their account</li>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" aria-label="close" onClick={() => { toggleModal('modal-groupMemberInfo') }}>Close</button>
                    </footer>
                </div>
            </div>
        </>
    )
}

export const GroupMemberListOnly = ({ groupId, members, limit, showAddMember }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [groupMembers, setGroupMembers] = useState(members?.members || {});

    const init = async () => {
        if (members) {
            setGroupMembers(members.members);
            setIsLoading(false);
            return;
        }

        await _getGroupMembers(groupId).then((members) => {
            setGroupMembers(members.members);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        init();
    }, []);

    const memberRef = collection(db, 'groups', groupId, 'members');

    useEffect(() => {
        const trackMembers = onSnapshot(memberRef, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                _updateMemberInCache(groupId, change.doc.id, change.doc.data(), change.type);
            });

            _getGroupMembersFromCache(groupId).then((members) => {
                setGroupMembers(members);
            });
        });

        return () => {
            trackMembers(); // Unsubscribe from the snapshot listener when the component unmounts
        };
    }, []);

    if (isLoading) {
        return <Loading />
    }


    return (
        <>
            {/* Use a Card with a heard instead with materialize menu up and menu down icons  */}
            <div className="box has-background-info-95 has-wrap-anywhere">
                <div className="is-size-6 has-text-weight-semibold">
                    Group Members <Icon path={mdiInformationSlabCircleOutline} size={.75} color="black" onClick={() => toggleModal("modal-groupMemberInfo")} /><br />
                </div>
                {showAddMember &&
                    <>
                        <div className="has-text-centered is-fullwidth">
                            <AddMember addMember={_saveNewGroupMember} groupId={groupId} buttonClass="is-primary is-rounded is-small is-outlined mr-5" />
                        </div>
                    </>
                }

                {groupMembers && Object.keys(groupMembers).sort((a, b) => { return groupMembers[a].displayName.localeCompare(groupMembers[b].displayName) }).slice(0, limit || 1000).map((memberId, index) => {
                    return (
                        <div key={"memberList_" + index}>
                            {groupMembers[memberId].displayName}
                        </div>
                    )
                })}
            </div>

            <div id="modal-groupMemberInfo" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Group Members</p>
                        <button className="delete" aria-label="close" onClick={() => { toggleModal('modal-groupMemberInfo') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="is-size-6">
                            <li>The member list is used primarily to pre-fill the names for the games you log</li>
                            <li>Any member of the group can add a member </li>
                            <li>Members can be removed by only by an admin of the group</li>
                            <li>When you send the ivite link to members and they join they can link their name to their account</li>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" aria-label="close" onClick={() => { toggleModal('modal-groupMemberInfo') }}>Close</button>
                    </footer>
                </div>
            </div>
        </>
    )
}

export const AddMember = ({ addMember, buttonClass, groupId }) => {

    const addNewMember = async () => {
        const newMemberName = document.getElementById("newMemberName").value;
        await addMember(groupId, newMemberName);
        document.getElementById("newMemberName").value = "";
        toggleModal("modal-addMember");
    }

    return (
        <>
            <button className={"button " + buttonClass} onClick={() => { toggleModal("modal-addMember") }}>Add Member</button>
            <div id="modal-addMember" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Add Member</p>
                        <button className="delete" aria-label="close" onClick={() => { toggleModal('modal-addMember') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Name</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input className="input" type="text" id="newMemberName" placeholder="Member Name" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="is-size-6">
                            Enter the display name for the member. When you send the ivite link to members and they join they can link their name to their account.

                        </div>
                    </section>
                    <footer className="modal-card-foot has-text-right">
                        <button className="button is-primary" onClick={() => { addNewMember(); }}>Add Member</button>
                        <button className="button" aria-label="close" onClick={() => { toggleModal('modal-addMember') }}>Close</button>
                    </footer>
                </div>
            </div>
        </>
    )
};