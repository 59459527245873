import React from 'react';
import Icon from '@mdi/react';
import { mdiShareVariant, mdiContentCopy, mdiQrcode } from '@mdi/js';
import { toggleModal } from '../utils/uiUtils';
import { QRCode } from 'react-qrcode-logo';
import logo24 from '../media/logo24.png';

function GroupInviteLink({ groupId, inviteCode }) {

    const inviteLink = (process.env.NODE_ENV === 'production' ? 'https://mygame.group' : 'http://localhost:3000') + `/joinGroup/${groupId}`;

    const handleMobileShare = () => {
        navigator.share({
            title: 'Join my game group!',
            text: `Use the invite link and the code ${inviteCode} to join my game group!`,
            url: inviteLink
        });
    }

    const handleCopyToClipboard = () => {
        const inviteLink = document.getElementById("inviteLink");
        if (inviteLink) {
            // Select the text field
            inviteLink.select();
            inviteLink.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(inviteLink.value);
            const linkCopiedNotification = document.getElementById("linkCopiedNotification");
            linkCopiedNotification.classList.remove("is-invisible");

            // Fade the element away over 5 seconds
            if (linkCopiedNotification) {
                setTimeout(() => {
                    linkCopiedNotification.classList.add("is-invisible");

                }, 5000);
            }
        }
    };

    return (
        <>
            <div className='box has-background-info-95 has-wrap-anywhere'>
                <div className="is-size-6 has-text-weight-semibold">
                    <span className="mr-3">Invite Link</span>
                    <span className="mr-2 is-clickable is-hidden-mobile" onClick={handleCopyToClipboard}><Icon size={.8} path={mdiContentCopy} /></span>
                    <span className={"mr-2 is-clickable " + (navigator.share ? "" : "is-hidden")} onClick={handleMobileShare}><Icon size={.8} path={mdiShareVariant} /></span>
                    <span className="is-clickable" onClick={() => {toggleModal("modal-QRCode"); }}><Icon size={.8} path={mdiQrcode} /></span> <br />
                </div>
                <div className="mt-1 is-size-7">Invite Code: {inviteCode}</div>

                <div id="modal-QRCode" className="modal">
                    <div className="modal-background"></div>
                    <div className="modal-card">
                        <header className="modal-card-head has-text-centered">
                            <p className="modal-card-title">Join Our Group</p>
                            <button className="delete" aria-label="close" onClick={() => { document.getElementById('modal-QRCode').classList.remove('is-active') }}></button>
                        </header>
                        <section className="modal-card-body">
                            <div className="is-flex is-justify-content-center">
                                <QRCode value={inviteLink} size={256} qrStyle="dots" bgColor='#34495e' fgColor='#FFF' 
                                    logoImage={logo24} 
                                    logoPadding={2} 
                                    logoWidth={54}
                                    logoPaddingStyle='circle' />
                            </div>
                            <div className="has-text-centered is-size-4">
                                Use invite code <span className='has-text-weight-semibold'> {inviteCode} </span>
                            </div>
                        </section>    
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupInviteLink;