import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, setTokenAutoRefreshEnabled } from "firebase/app-check";
import { getFirestore, connectFirestoreEmulator  } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import ReactObserver from 'react-event-observer';
import { connectStorageEmulator } from 'firebase/storage';
import { getStorage } from "firebase/storage";

var firebaseConfig = {};
if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

    firebaseConfig = {
        apiKey: "AIzaSyAZ8l3vKzEB4TEKTm90GBxn6Qf3Y9s_Z0A",
        authDomain: "mygamegroup-dev.firebaseapp.com",
        projectId: "mygamegroup-dev",
        storageBucket: "mygamegroup-dev.appspot.com",
        messagingSenderId: "996285369076",
        appId: "1:996285369076:web:6992b04088b73ba8946ca1"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyAgfDJNwJFw3boHC9M7fHnBbieQxQV7MFQ",
        authDomain: "mygame.group",
        projectId: "mygamegroup-prod",
        storageBucket: "mygamegroup-prod.appspot.com",
        messagingSenderId: "216517042551",
        appId: "1:216517042551:web:333d3a03df1f6ea3dcc172"
    };
}

const firebaseApp = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider("6LfxxKEpAAAAAEYOPLc3BKXVIE19LgC8-elQL9sP"),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

if (window.navigator.userAgent.includes("Safari") && !window.navigator.userAgent.includes("Chrome")) {
    setTokenAutoRefreshEnabled(appCheck, false);
}

export const config = firebaseConfig;
export const db = getFirestore(firebaseApp);
export const auth = getAuth();
const storage = getStorage();

if (window.location.hostname === "127.0.0.1") {
    console.log("127.0.0.1 detected!");
    connectFirestoreEmulator(db, "localhost", "8080");
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectStorageEmulator(storage, "127.0.0.1", "9199");
} 

export const firebaseObserver = ReactObserver();
auth.onAuthStateChanged(function (user) {
    firebaseObserver.publish("authStateChanged", loggedIn())
});
export function loggedIn() {
    return !!auth.currentUser;
}