import './App.css';
import './styles/mygamegroup.css';
import SiteNav from './components/SiteNav';
import { useEffect, useState } from 'react';
import NotSignedIn from './components/NotSignedIn';
import MainPage from './components/MainPage';
import Loading from './components/Loading';
import { auth } from './utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setIsLoading(false);
     if (currentUser) { 
            // Note: this logic should be added in your signin process and not here.
            setIsLoggedIn(true);  
          } else {
            setIsLoggedIn(false);  
         }
        });
    return () => {
      unsubscribe();
    };
    }, []);

  return (
    <>
      <SiteNav />
      <div className='container is-fullheight'>
        {isLoading ? <Loading />: null}

        {!isLoading && !isLoggedIn ? <NotSignedIn /> : null}

        {!isLoading && isLoggedIn ? <MainPage /> : null}
      </div>
    </>
  );
}

export default App;
