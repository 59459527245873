import React, { useState, useEffect } from "react";
import { Hint } from 'react-autocomplete-hint';
import { _getAllGames } from "../data/games";
import { _getGroupMembers, _logGamePlayed, _getGamesPlayed } from "../data/groups";
import Icon from '@mdi/react';
import { mdiDeleteEmpty as deleteIcon, mdiAccountPlus } from '@mdi/js';
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { _getGroupMembersFromCache } from "../data/localCache";

function LogGame({ groupId }) {
    const [games, setGames] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [playerCount, setPlayerCount] = useState(0);
    const [gameDate, setGameDate] = useState();
    const [members, setMembers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isCoop, setIsCoop] = useState(false);
    const [lowScoreWins, setLowScoreWins] = useState(false);

    const navigate = useNavigate();
    const init = async () => {
        // ToDo: Make this a bundle
        _getGamesPlayed(groupId).then((gamesPlayed) => {
            var tmpGames = [];
            Object.keys(gamesPlayed).forEach((gameId) => {
                tmpGames.push(gameId);
            });

            setGames(tmpGames);
        });

        if (sessionStorage.getItem("gameDate")) {
            setGameDate(sessionStorage.getItem("gameDate"));
        } else {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            setGameDate(formattedDate);
        }

        // Try to get members from session and load them if not in session
        _getGroupMembersFromCache(groupId).then((members) => {
            setMembers(Object.keys(members).filter(memberId => !members[memberId].inactive).reduce((obj, memberId) => {
                obj[memberId] = members[memberId];
                return obj;
            }, {}));
        });

        var tmpPlayers = sessionStorage.getItem("playerList");
        if (!tmpPlayers) {
            addPlayer();
        } else {
            var playerList = tmpPlayers.split(",");
            setPlayerList(playerList);
            setPlayerCount(playerList.length);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        init();
    }, []);

    const addPlayer = () => {
        if (playerCount >= Object.keys(members).length) {
            return;
        }

        const tmpPlayers = [...playerList];
        const availableMembers = Object.keys(members).filter(memberId => !tmpPlayers.includes(memberId));
        const newPlayer = availableMembers.length > 0 ? availableMembers[0] : "";
        tmpPlayers.push(newPlayer);
        setPlayerList(tmpPlayers);
        sessionStorage.setItem("playerList", tmpPlayers);
        setPlayerCount(playerCount + 1);
        if (tmpPlayers.length >= Object.keys(members).length) {
            document.getElementById("addPlayer").classList.add("is-hidden");
        }
    }

    const removePlayer = (index) => {
        var tmpPlayers = [...playerList];
        tmpPlayers.splice(index, 1);
        setPlayerList(tmpPlayers);
        sessionStorage.setItem("playerList", tmpPlayers);
        setPlayerCount(playerCount - 1);
        if (tmpPlayers.length < Object.keys(members).length) {
            document.getElementById("addPlayer").classList.remove("is-hidden");
        }
    }

    const changePlayerName = (index, newName) => {
        let data = [...playerList];
        data[index] = newName;
        setPlayerList(data);
        sessionStorage.setItem("playerList", data);
    }

    const getPlayerForSpot = (index) => {
        var player = playerList[index] ?? "";
        return player;
    }

    const saveCoop = (won) => {
        const coopScore = document.getElementById("coopScore").value;
        const game = document.getElementById("game").value;
        var dateSplit = document.getElementById("gameDate").value.split("-");
        var gameDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
        const formattedDate = gameDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }).replace(/\//g, "-");
        var players = [];
        for (var i = 0; i < playerCount; i++) {
            players.push({ member: playerList[i] });
        }

        var gameData = {
            game: game,
            players: players,
            isCoop: true,
            gameWon: won,
            gameScore: coopScore
        }

        if (lowScoreWins) {
            gameData.lowScoreWins = true;
        }

        _logGamePlayed(groupId, formattedDate, gameData).then(() => {
            document.getElementById("logGameCompleted").classList.remove("is-hidden");
            document.getElementById("game").value = "";
            document.getElementById("coopScore").value = "";
            document.getElementById("coopCheckbox").checked = false;
            setIsCoop(false);

            if (window.location.pathname == "/group/" + groupId) {
                navigate("/groupSession/" + groupId, { state: { sessionId: formattedDate, groupMembers: members } });
            }
        });
    }

    const saveGamePlayed = () => {
        document.getElementById("logGameValidationError").classList.add("is-hidden");
        var game = document.getElementById("game").value;
        var dateSplit = document.getElementById("gameDate").value.split("-");
        var gameDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
        var formattedDate = gameDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }).replace(/\//g, "-");

        var scores = [];
        var winners = [];
        for (var i = 0; i < playerCount; i++) {
            scores.push({ member: playerList[i], score: document.getElementById("score-" + i).value });

            if (document.getElementById("winner-" + i).checked) {
                winners.push(playerList[i]);
            }
        }

        if (!game || dateSplit.length !== 3) {
            document.getElementById("logGameValidationError").classList.remove("is-hidden");
            return;
        }

        if (Object.values(scores).every(x => x === null || x === '') && winners.every(winner => !winner)) {
            document.getElementById("logGameValidationError").classList.remove("is-hidden");
            return;
        }

        var gameData = {
            game: game,
            players: scores,
            winners: winners
        }

        if (lowScoreWins) {
            gameData.lowScoreWins = true;
        }

        _logGamePlayed(groupId, formattedDate, gameData).then(() => {
            document.getElementById("logGameCompleted").classList.remove("is-hidden");
            document.getElementById("game").value = "";
            document.getElementById("coopScore").value = "";
            document.getElementById("coopCheckbox").checked = false;
            for (var i = 0; i < playerCount; i++) {
                document.getElementById("score-" + i).value = "";
                document.getElementById("winner-" + i).checked = false;
            }

            if (window.location.pathname == "/group/" + groupId) {
                navigate("/groupSession/" + groupId, { state: { sessionId: formattedDate, groupMembers: members } });
            }
        });
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="container">
                <header className="has-text-centered mb-4">
                    <p className="subtitle">Log Game Play</p>
                </header>
                <section>
                    <div id="logGameValidationError" className="is-hidden notification has-background-danger-light has-text-dark has-text-weight-bold">
                        Be sure you have selected a game and date, and either entered scores or checked the winner box for at least one player.
                    </div>
                    <div id="logGameCompleted" className="is-hidden notification has-background-success-light has-text-dark has-text-weight-bold">
                        <button className="delete" onClick={() => { document.getElementById("logGameCompleted").classList.add("is-hidden") }}></button>
                        Game Saved.
                    </div>
                    <div className="columns is-mobile">
                        <div className="column is-4-desktop is-6-mobile is-6-tablet">
                            Game
                            <Hint options={games} allowTabFill='true'>
                                <input type="text" id="game" className="input is-small" ></input>
                            </Hint>
                            <div>
                                <label className="checkbox">
                                    <input type="checkbox" id="lowScoreWinsCheckbox" className="mt-3" onClick={(event) => { setLowScoreWins(event.currentTarget.checked); }} />
                                    <span className="ml-2">Low Score Wins</span>
                                </label>
                            </div>
                            <label className="checkbox">
                                <input type="checkbox" id="coopCheckbox" className="mt-3" onClick={(event) => { setIsCoop(event.currentTarget.checked); }} />
                                <span className="ml-2">Coop Game</span>
                            </label>
                        </div>
                        <div className="column is-4-desktop is-6-mobile is-6-tablet">
                            <div>Date played</div>
                            <div>
                                <input type="date" id="gameDate" className="input" defaultValue={gameDate}></input>
                            </div>
                        </div>
                    </div>
                    <div className={"columns is-multiline is-mobile " + (isCoop ? "" : "is-hidden")}>
                        <div className="column is-2-desktop is-6-mobile is-3-tablet">
                            <button className="button is-primary is-rounded" onClick={() => { saveCoop(true) }}>Won</button>
                        </div>
                        <div className="column is-2-desktop is-6-mobile is-3-tablet">
                            <button className="button has-background-danger is-rounded" onClick={() => { saveCoop(false) }}>Lost</button>
                        </div>
                        <div className="column is-12-desktop is-16-mobile is-12-tablet">
                            Optional Score <br />
                            <input type="text" id="coopScore" className="input is-small is-rounded" placeholder="Score"></input>
                        </div>

                    </div>
                    <div>
                        <table className={"table " + (isCoop ? "" : " is-fullwidth")}>
                            <thead>
                                <tr>
                                    <th>Player</th>
                                    <th className={"pl-5 " + (isCoop ? "is-hidden" : "")}>Score</th>
                                    <th className={"pl-5 " + (isCoop ? "is-hidden" : "")}>Winner</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {playerList.map((player, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={getPlayerForSpot(index)} className="select is-small" onChange={(event) => { changePlayerName(index, event.target.value) }}>
                                                {Object.keys(members).map((memberId, index) => {
                                                    return (
                                                        <option key={index} value={memberId}>{members[memberId].displayName}</option>
                                                    )
                                                })}
                                            </select>
                                        </td>
                                        <td className={"pl-5 " + (isCoop ? "is-hidden" : "")}>
                                            <input type="number" id={"score-" + index} className="input is-small text-box-width-short" />
                                        </td>
                                        <td className={"pl-5 has-text-centered " + (isCoop ? "is-hidden" : "")}>
                                            <label className="checkbox">
                                                <input id={"winner-" + index} type="checkbox" className="" />
                                            </label>
                                        </td>
                                        <td>
                                            <Icon path={deleteIcon} size={1} className="is-clickable" color="grey" onClick={() => { removePlayer(index) }} />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="has-text-centered">
                                        <div id="addPlayer">
                                            <Icon path={mdiAccountPlus} size={1.25} className="is-clickable" onClick={() => { addPlayer() }} />
                                        </div>
                                    </td>
                                    <td></td>
                                    <td colSpan="3">
                                        <button className={"button is-primary " + (isCoop ? "is-hidden" : "")} onClick={() => { saveGamePlayed() }}>Save</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>

                    </div>
                </section>
            </div>
        </>
    )
}

export default LogGame;