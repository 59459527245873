import React from "react";
import { signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail, confirmPasswordReset, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase.js';
import { postSignInCheck } from "../data/users.js";
import Icon from '@mdi/react';
import { mdiAt, mdiLock, mdiBadgeAccountHorizontalOutline, mdiCheckBold } from '@mdi/js';
import GoogleIcon from '../media/google-icon.svg';
import { toggleModal } from "../utils/uiUtils.js";

function NotSignedIn() {
    const googleHandler = async () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        provider.setCustomParameters({ prompt: 'select_account' });
        await signInWithPopup(auth, provider)
            .then(async (result) => {
                // await postSignInCheck();
            }).catch((exception) => {
                console.log(exception);
            });
    };

    const SignInWithEmailAndPasswordHandler = async () => {
        var email = document.getElementById("loginEmail").value;
        var password = document.getElementById("loginPassword").value;
        signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
          
        }).catch(() => {
            document.getElementById("loginFailedMessage").classList.remove("is-hidden");
        });
    };

    const signUpWithEmailHandler = async () => {
        var email = document.getElementById("signupEmail").value;
        var displayName = document.getElementById("signupDisplay").value;
        var password = document.getElementById("signupPassword").value;
        var passwordConfirm = document.getElementById("signupPasswordConfirm").value;
        var formValid = true;

        document.getElementById("signupEmail").classList.remove("is-danger");
        document.getElementById("signupDisplay").classList.remove("is-danger");
        document.getElementById("signupPassword").classList.remove("is-danger");
        document.getElementById("signupPasswordConfirm").classList.remove("is-danger");


        if (!email) {
            formValid = false;
            document.getElementById("signupEmail").classList.add("is-danger");
        }

        if (!displayName) {
            formValid = false;
            document.getElementById("signupDisplay").classList.add("is-danger");
        }

        if (!checkPasswordStrength(password)) {
            formValid = false;
            document.getElementById("signupPassword").classList.add("is-danger");
        }

        if (password !== passwordConfirm) {
            formValid = false;
            document.getElementById("signupPasswordConfirm").classList.add("is-danger");
        }

        if (!formValid) {
            return;
        }

        createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            postSignInCheck(displayName);
            // ...
        }).catch(() => {
            document.getElementById("signupFailedMessage").classList.remove("is-hidden");
        });
    }

    const checkPasswordStrength = (password) => {
        var passwordStrength = 0;

        if (password.match(/[0-9]/)) {
            passwordStrength++;
        }

        if (password.match(/[^a-zA-Z0-9]/)) {
            passwordStrength++;
        }

        if (password.length >= 8) {
            passwordStrength++;
        }

        return passwordStrength === 3;
    }

    const checkPassword = (password, passwordStrengthIcon, passwordConfirm, passwordConfirmIcon) => {
        var passwordMeetsRequirements = checkPasswordStrength(password);

        if (passwordMeetsRequirements) {
            passwordStrengthIcon.classList.remove("is-hidden");
            document.getElementById("signupPassword").classList.remove("is-danger");
            if (password === passwordConfirm) {
                passwordConfirmIcon.classList.remove("is-hidden");
                document.getElementById("signupPassword").classList.remove("is-danger");
            } else {
                passwordConfirmIcon.classList.add("is-hidden");
            }
        } else {
            passwordStrengthIcon.classList.add("is-hidden");
            passwordConfirmIcon.classList.add("is-hidden");
        }
    }

    const resetPasswordHandler = () => {
        var email = document.getElementById("loginEmail").value;
        sendPasswordResetEmail(auth, email)
            .then(() => {
                toggleModal('modal-login');
                toggleModal('modal-reset-password');
            });
    }

    return (
        <>
            <div className="columns ml-2 mr-2 mt-5">
                <div className="column is-3-desktop is-offset-2 is-12-mobile">
                    <div>
                        This site requires you to sign in with one of the following providers.
                    </div>
                    <br />
                    <div className="content has-text-left">
                        <li>Join and create Game Groups</li>
                        <li>Logs games and winners from play sessions</li>
                        <li>Rate games and see stats from your group</li>
                    </div>
                </div>
                <div className="column is-offset-3 is-3-desktop is-12-mobile">
                    <div className="has-text-centered">
                        <div className="mb-4 has-text-primary">
                            <div className="field is-full-width">
                                <p className="control has-icons-left has-icons-right ">
                                    <button className="input is-normal is-fullwidth" onClick={googleHandler}>
                                        <span className="icon is-left pb-1">
                                            <figure className=" image is-24x24">
                                                <img src={GoogleIcon} />
                                            </figure>
                                        </span>
                                        Log in with Google
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div className=" has-text-left">
                            <div className="field">
                                <p className="control">
                                    <button className="button is-primary is-fullwidth" onClick={() => { toggleModal("modal-login"); }}>
                                        Login with Password
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div className="field">
                            <p className="control">
                                <button className="button is-outlined is-primary is-fullwidth mt-3" onClick={() => { toggleModal("modal-signUp") }}>
                                    Sign Up With Email
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-signUp" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Sign Up with Email</p>
                        <button className="delete" aria-label="close" onClick={() => { document.getElementById('modal-signUp').classList.remove('is-active') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div>All fields are required</div>
                        <div className="field is-full-width">
                            <p className="control has-icons-left has-icons-right ">
                                <input id="signupDisplay" className="input is-normal is-fullwidth" type="text" placeholder="Display Name" />
                                <span className="icon is-small is-left has-text-primary">
                                    <Icon path={mdiBadgeAccountHorizontalOutline} size={1} />
                                </span>
                            </p>
                        </div>
                        <div className="field is-full-width">
                            <p className="control has-icons-left has-icons-right ">
                                <input id="signupEmail" className="input is-normal is-fullwidth" type="email" placeholder="Email" />
                                <span className="icon is-small is-left has-text-primary">
                                    <Icon path={mdiAt} size={1} />
                                </span>
                            </p>
                        </div>
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input id="signupPassword" className="input is-normal is-fullwidth" type="password" placeholder="Password" 
                                    onChange={() => { checkPassword(document.getElementById("signupPassword").value, document.getElementById("passwordStrengthIcon"), document.getElementById("signupPasswordConfirm").value, document.getElementById("passwordConfirmIcon")) }} />
                                <span className="icon is-small is-left has-text-primary">
                                    <Icon path={mdiLock} size={1} />
                                </span>
                                <span id="passwordStrengthIcon" className="is-hidden icon is-small is-right has-text-primary">
                                    <Icon path={mdiCheckBold} color="green" size={1} />
                                </span>
                                Must be at least 8 characters long, with at least one number and one special character.
                            </p>
                        </div>
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input id="signupPasswordConfirm" className="input is-normal is-fullwidth" type="password" placeholder="Confirm Password"
                                    onChange={() => { checkPassword(document.getElementById("signupPassword").value, document.getElementById("passwordStrengthIcon"), document.getElementById("signupPasswordConfirm").value, document.getElementById("passwordConfirmIcon")) }} />
                                <span className="icon is-small is-left has-text-primary">
                                    <Icon path={mdiLock} size={1} />
                                </span>
                                <span id="passwordConfirmIcon" className="icon is-hidden is-small is-right has-text-primary">
                                    <Icon path={mdiCheckBold} color="green" size={1} />
                                </span>
                            </p>
                        </div>
                        <div className="is-hidden has-text-weight-bold has-text-danger-dark" id="signupFailedMessage">
                            Something went wrong. If you have an account try to login or reset your password. Or check your email is valid and try again.
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-primary" onClick={() => { signUpWithEmailHandler() }}>Sign Up</button>
                        <button className="button" aria-label="close" onClick={() => { document.getElementById('modal-signUp').classList.remove('is-active') }}>Close</button>
                    </footer>
                </div>
            </div>

            <div id="modal-login" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Log in With Email and Password</p>
                        <button className="delete" aria-label="close" onClick={() => { document.getElementById('modal-login').classList.remove('is-active') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className=" has-text-left">
                            <div className="field is-full-width">
                                <p className="control has-icons-left has-icons-right ">
                                    <input id="loginEmail" className="input is-normal is-fullwidth" type="email" placeholder="Email" />
                                    <span className="icon is-small is-left has-text-primary">
                                        <Icon path={mdiAt} size={1} />
                                    </span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input id="loginPassword" className="input is-normal is-fullwidth" type="password" placeholder="Password" />
                                    <span className="icon is-small is-left has-text-primary">
                                        <Icon path={mdiLock} size={1} />
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="is-hidden has-text-weight-bold has-text-danger-dark" id="loginFailedMessage">
                            Something went wrong. Check your email and password and try again, or click to <span className="is-link"><a href="#" onClick={() => { resetPasswordHandler(); }}>Reset Your Password</a></span> (a link will be sent to the email entered above).

                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-primary" onClick={() => { SignInWithEmailAndPasswordHandler() }}>Log In</button>
                        <button className="button" aria-label="close" onClick={() => { document.getElementById('modal-login').classList.remove('is-active') }}>Close</button>
                    </footer>
                </div>
            </div>

            <div id="modal-reset-password" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Reset Your Password</p>
                        <button className="delete" aria-label="close" onClick={() => { toggleModal('modal-reset-password') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <p>If an account for this email was found in our system a link to reset your password will be sent to it. 
                            Follow the instructions to reset your password, then come back to the site and log in again.</p>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" aria-label="close" onClick={() => { toggleModal('modal-reset-password') }}>Close</button>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default NotSignedIn;