import Group from './components/Group.js';
import JoinGroup from './components/JoinGroup.js';
import { GroupSession } from './components/GroupSession.js';
import SiteNav from './components/SiteNav.js';
import { useLoaderData } from 'react-router-dom';
import RateGames from './components/RateGames.js';
import LogGame from './components/LogGame.js';
import { GroupMembers } from './components/GroupMembers.js';
import GroupFooter from './components/GroupFooter.js';
import GroupSessionList from './components/GroupSessionList.js';
import GroupNameHeader from './components/GroupNameHeader.js';
import GameStatsForGroup from './components/GameStatsForGroup.js';
import GroupEditGame from './components/GroupEditGame.js';
import GroupEditIcon from './components/GroupEditIcon.js';

export async function loader({ params }) {
    return params.id;
}

export const GroupPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <Group groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const JoinGroupPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <JoinGroup groupId={groupId} />
        </>
    )
}

export const GroupSessionPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <GroupSession groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const RateGamesPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <RateGames groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const LogGamesPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <LogGame groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const GroupMembersPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <GroupMembers groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const GroupSessionsPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <GroupSessionList groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const GameStatsPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <GameStatsForGroup groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const GroupEditGamePage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <GroupEditGame groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}

export const GroupEditIconPage = () => {
    const groupId = useLoaderData();
    return (
        <>
            <SiteNav />
            <GroupNameHeader groupId={groupId} />
            <GroupEditIcon groupId={groupId} />
            <GroupFooter groupId={groupId} />
        </>
    )
}