import React, { useState, useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { _getGamesPlayed, _rateGame } from '../data/groups';
import { _getUserRatedGames } from '../data/users';
import { db, firebaseObserver, loggedIn } from '../utils/firebase';
import Icon from '@mdi/react';
import { mdiStar, mdiStarHalf } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

function RateGames({ groupId }) {
    const [gamesPlayed, setGamesPlayed] = useState();
    const [userRatedGames, setUserRatedGames] = useState();
    const [isLoading, setIsLoading] = useState(true);

    var navigate = useNavigate();
    const getAllData = async () => {
        await Promise.all([_getGamesPlayed(groupId), _getUserRatedGames(groupId)]).then(([gamesPlayed, gamesRated]) => {
            setGamesPlayed(gamesPlayed);
            setUserRatedGames(gamesRated);
            setIsLoading(false);
        })
    };

    const rateGame = async (game, rating) => {
        await _rateGame(groupId, game, rating);
        const tmpRatings = { ...userRatedGames };
        tmpRatings[game] = rating;
        setUserRatedGames(tmpRatings);
        var tmpGamesPlayed = { ...gamesPlayed };
        tmpGamesPlayed[game].rating = -1;
        setGamesPlayed(tmpGamesPlayed);
    }

    const displayRating = (game, rating) => {
        if (rating == 0 || rating == null) {
            return <></>;
        }

        if (rating === -1) {
            return (
                <div className="lds-dual-ring"></div>
            );
        }

        var roundedRating =  Math.round(gamesPlayed[game].rating * 2) / 2;
        var icons = [];
        for (var i = 1; i <= roundedRating; i++) {
            icons.push(<Icon key={game + "_" + i} path={mdiStar} size={.9} color="#CC9900" />);
        }

        if (roundedRating % 1 !== 0) {
            icons.push(<Icon key={game + "_point5"} path={mdiStarHalf} size={.9} color="#CC9900" />);
        }

        return (
            <>
                {icons}
            </>
        )
    }

    const groupRef = doc(db, 'groups', groupId);

    useEffect(() => {
        const trackGroupChanges = onSnapshot(groupRef, (snapshot) => {
            setGamesPlayed(snapshot.data().gamesPlayed);
        });

        return () => {
            trackGroupChanges(); // Unsubscribe from the snapshot listener when the component unmounts
        };
    }, []);


    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', async (isUserLoggedIn) => {
            getAllData();
        });
    }, []);

    useEffect(() => {
        if (loggedIn()) {
            getAllData();
        }
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="ml-2 mr-2">
                <div className="columns">
                    <div className="column is-half ml-auto mr-auto">
                        <div className="box">
                            <h1 className="title">Rate Games</h1>

                            <div className="mb-4 mt-0 is-size-7">
                                You can ony rate games your group has played. If you're in multiple groups you need to rate the games in each group separately.
                                Click on Your Rating to change the rating for a game. The Group Rating is the average rating for the group and will update automatically.
                            </div>
                            <button className="button is-primary is-rounded is-small is-outlined mb-4 mr-5" onClick={() => { navigate("/group/" + groupId) }}>Back to Group</button>
                            <table className="table is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Game</th>
                                        <th>Your Rating</th>
                                        <th>Group Rating</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gamesPlayed && Object.keys(gamesPlayed).sort().map((game, index) => (
                                        <tr key={index}>
                                            <td>{game}</td>
                                            <td>
                                                <Icon path={mdiStar} size={.9} color={(userRatedGames[game] >= 1 ? "navy" : "lightgray")} onClick={() => { rateGame(game, 1); }} />
                                                <Icon path={mdiStar} size={.9} color={(userRatedGames[game] >= 2 ? "navy" : "lightgray")} onClick={() => { rateGame(game, 2); }} />
                                                <Icon path={mdiStar} size={.9} color={(userRatedGames[game] >= 3 ? "navy" : "lightgray")} onClick={() => { rateGame(game, 3); }} />
                                                <Icon path={mdiStar} size={.9} color={(userRatedGames[game] >= 4 ? "navy" : "lightgray")} onClick={() => { rateGame(game, 4); }} />
                                                <Icon path={mdiStar} size={.9} color={(userRatedGames[game] == 5 ? "navy" : "lightgray")} onClick={() => { rateGame(game, 5); }} />
                                            </td>
                                            <td>
                                                {displayRating(game, gamesPlayed[game].rating)}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RateGames;