import React from "react";
import { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { get } from "firebase/database";
import { _setGroupIcon } from "../data/groups";
import { useNavigate } from "react-router-dom";

function GroupEditIcon({ groupId }) {
    const [iconList, setIconList] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const storage = getStorage();
        const iconsRef = ref(storage, 'group_icons/light/');
        var icons = {};
        listAll(iconsRef)
            .then((res) => {
                const promises = res.items.map((itemRef) => {
                    if (itemRef.name === "addgroup.png") return Promise.resolve();

                    return getDownloadURL(itemRef).then((url) => {
                        icons[itemRef.name.split('.')[0]] = url;
                    });
                });
                return Promise.all(promises).then(() => icons);
            })
            .then((icons) => {
                setIconList(icons);
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
                console.log(error);
            });

    }, []);

    const setGroupIcon = async (icon) => {
        await _setGroupIcon(groupId, icon);
        navigate("/group/" + groupId);
    }

    return (
        <div className="container pl-2">
            <div className="mr-auto ml-auto has-text-weight-semibold is-size-5 has-text-centered">
                Edit Icon
            </div>
            <div>
                Select a new icon for your group from the ones below.
            </div>
            
                <div className="grid">

                    {Object.keys(iconList).sort().map((icon, index) => {
                        return (
                            <div className="cell" key={index}>
                                <figure className="image has-text-centered is-48x48 ml-auto mr-auto" onClick={() => { setGroupIcon(icon) }}>
                                    <img src={iconList[icon]} alt="Group Icon" />
                                </figure>
                            </div>
                        )
                    })}
                </div>
            
        </div>
    )
}

export default GroupEditIcon;