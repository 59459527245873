import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { mdiAccountGroup, mdiStarHalfFull, mdiPodium, mdiChartLine, mdiCalendarMonth, mdiHome } from '@mdi/js';
import { useNavigate } from "react-router-dom";
import { _getGroupIcon } from "../data/groups";

function GroupNameHeader({groupId}) {
    const [groupName, setGroupName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [groupIcon, setGroupIcon] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const handleStorageChange = () => {
            setGroupIcon(sessionStorage.getItem('groupIcon'));
            setGroupName(sessionStorage.getItem('groupName'));
        };
    
        window.addEventListener('storage', handleStorageChange);
    
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    useEffect(() => {
        setTimeout(() => { 
            setGroupName(sessionStorage.getItem("groupName")) 
            _getGroupIcon(groupId).then((icon) => {
                setGroupIcon(icon);
            });
        }, 500);
    }, []);

    return (
        <section id="groupNameHeader" className="hero is-extra-small has-background-info-95 mb-2 mt-3">
            <div className="hero-body">
                <div className="mr-auto ml-auto has-text-weight-semibold is-size-4">
                    {groupIcon && groupIcon.length > 1 && 
                        <figure className="image is-48x48 ml-auto mr-auto is-size-7" onClick={() => { navigate(`/groupEditIcon/${groupId}`); }}>
                            <img src={groupIcon} alt="Group Icon" />
                        </figure>
                    }
                    {groupName || <span>&nbsp;</span>}
                </div>
            </div>
            <div className="is-hidden-mobile is-hidden-tablet-only has-text-centered ">
                <div className="columns is-centered">
                    <div className="column is-1 has-text-centered has-text-primary" onClick={() => { navigate(`/group/${groupId}`)}}>
                        <span className="icon-text">
                            <span className="icon is-clickable">
                                <Icon path={mdiHome} size={1.25} /><br />
                            </span>
                        </span>
                        <div className="block has-text-primary is-size-7 is-clickable">Group Home</div>
                    </div>
                    <div className="column is-1 has-text-centered has-text-primary" onClick={() => {navigate(`/logGame/${groupId}`)}}>
                        <span className="icon-text">
                            <span className="icon">
                                <Icon path={mdiPodium} size={1.25} className="is-clickable" /><br />
                            </span>
                        </span>
                        <div className="block has-text-primary is-size-7 is-clickable">Log Game</div>
                    </div>
                    <div className="column is-1 has-text-centered has-text-primary" onClick={() => { navigate(`/rateGames/${groupId}`)}}>
                        <span className="icon-text">
                            <span className="icon">
                                <Icon path={mdiStarHalfFull} size={1.25} className="is-clickable" /><br />
                            </span>
                        </span>
                        <div className="block has-text-primary is-size-7 is-clickable">Rate Games</div>
                    </div>
                    <div className="column is-1 has-text-centered has-text-primary is-clickable" onClick={() => { navigate(`/groupSessions/${groupId}`)}}>
                        <span className="icon-text">
                            <span className="icon">
                                <Icon path={mdiCalendarMonth} size={1.25} className="is-clickable" /><br />
                            </span>
                        </span>
                        <div className="block has-text-primary is-size-7">All Sessions</div>
                    </div>
                    <div className="column is-1 has-text-centered has-text-primary" onClick={() => { navigate(`/groupMembers/${groupId}`)}}>
                        <span className="icon-text">
                            <span className="icon">
                                <Icon path={mdiAccountGroup} size={1.25} className="is-clickable" /><br />
                            </span>
                        </span>
                        <div className="block has-text-primary is-size-7 is-clickable">Members</div>
                    </div>
                    <div className="column is-1 has-text-centered has-text-primary" onClick={() => { navigate(`/gameStats/${groupId}`)}}>
                        <span className="icon-text">
                            <span className="icon">
                                <Icon path={mdiChartLine} size={1.25} className="is-clickable" /><br />
                            </span>
                        </span>
                        <div className="block has-text-primary is-size-7 is-clickable">Game Stats</div>
                    </div>

                    
                </div>
            </div>
        </section>
    );
}

export default GroupNameHeader;