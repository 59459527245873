import React, { useState, useEffect } from "react";
import { db, firebaseObserver, loggedIn } from "../utils/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { _getGroup, _getGroupMembers, _saveNewGroupMember, _getSessions } from "../data/groups";
import GroupInviteLink from "./GroupInviteLink";
import { GroupMemberListOnly, AddMember } from "./GroupMembers";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import { _setMemberCache, _getGroupMembersFromCache, _updateMemberInCache } from "../data/localCache";
import Loading from "./Loading";

function Group({ groupId }) {
    const [myGroup, setMyGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [groupMembers, setGroupMembers] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [recentSessions, setRecentSessions] = useState([]);
    const navigate = useNavigate();

    const getAllData = async () => {
        await Promise.all([_getGroup(groupId), _getGroupMembers(groupId), _getSessions(groupId, 10)]).then(([tmpGroup, members, sessions]) => {
            setMyGroup(tmpGroup);
            sessionStorage.setItem("groupName", tmpGroup.groupName);            
            sessionStorage.removeItem("playerList");
            setGroupMembers(members);
            setRecentSessions(sessions);
            setIsLoading(false);
        });

    }

    const sortArray = (a, b) => {
        if (myGroup.gamesPlayed[b].count == myGroup.gamesPlayed[a].count) {
            return a.localeCompare(b);
        }

        return myGroup.gamesPlayed[b].count - myGroup.gamesPlayed[a].count
    }

    const roundRating = (rating) => {
        return Math.round(rating * 2) / 2;
    }

    useEffect(() => {
        var data = [['Game', 'Play Count', 'Rating', { role: 'annotation' }]];

        if (!myGroup) {
            return;
        }

        if (myGroup?.gamesPlayed) {
            const sortedGames = Object.keys(myGroup?.gamesPlayed).sort(sortArray);
            sortedGames.slice(0, 7).forEach((key, index) => {
                data.push([key, myGroup.gamesPlayed[key].count, roundRating(myGroup.gamesPlayed[key].rating), roundRating(myGroup.gamesPlayed[key].rating)]);
            });
            setChartData(data);
        }
    }, [myGroup]);

    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', async (isUserLoggedIn) => {
            getAllData();
        });
    }, []);

    useEffect(() => {
        if (loggedIn()) {
            getAllData();
        }
    }, []);

    const addMember = async (groupId, memberName) => {
        var newMember = await _saveNewGroupMember(groupId, memberName);
        var tmpMembers = groupMembers;
        tmpMembers[newMember.memberId] = newMember;
        setGroupMembers(tmpMembers);
        sessionStorage.removeItem("groupMembers");
    }

    if (isLoading) {
        return (
           <Loading />
        );
    }

    return (
        <>
            <div className="p-2">
                <div className="is-flex is-justify-content-center has-text-weigh-semibold is-underlined mb-4 mt-0" >
                    <AddMember addMember={addMember} buttonClass="is-primary is-rounded is-small is-outlined mr-5" groupId={groupId} />
                </div>
                <div className="container">
                    <div className="columns">
                        <div className="column is-2">
                            <GroupInviteLink groupId={groupId} inviteCode={myGroup.inviteCode} />
                            <GroupMemberListOnly groupId={groupId} members={groupMembers} addMember={addMember} limit="60" />
                        </div>
                        <div className="column is-8">
                            <div className={"is-hidden-mobile " + (chartData.length < 1 ? "is-hidden" : "")}>
                                <Chart chartType="ColumnChart" width="100%" height="400px" data={chartData} options={{ colors: ["#34495e", "#f1b70e"] }} />
                            </div>
                            <div className="is-hidden-desktop is-hidden-tablet">
                                <table className="table is-fullwidth has-background-primary-light">
                                    <tbody>
                                        <tr>
                                            <th>Game</th>
                                            <th className="has-text-centered">Plays</th>
                                            <th className="has-text-centered">Avg. Rating</th>
                                        </tr>
                                        {chartData.slice(1).map((game, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{game[0]}</td>
                                                    <td className="has-text-centered">{game[1]}</td>
                                                    <td className="has-text-centered">{isNaN(game[2]) ? "-" : Math.round(game[2] * 2) / 2}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                            <div className={chartData.length > 1 ? "is-hidden" : ""}>
                                No games played yet. After you start logging games you will see your most played ones here.
                            </div>
                        </div>
                        <div className={"column is-2 " + (recentSessions.length > 0 ? "" : "is-hidden")}>
                            <div className="box has-background-info-95 has-wrap-anywhere">
                                <div className="is-size-6 has-text-weight-semibold">
                                    Last Sessions<br />
                                </div>
                                <div>
                                    {recentSessions.slice(0, 5).map((session, index) => {
                                        return (
                                            <div key={index} onClick={() => { navigate("/groupSession/" + groupId, { state: { sessionId: session, groupMembers: groupMembers } }); }} className="is-clickable">
                                                {session}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Group;