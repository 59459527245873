import { _getGroupMembers } from './groups';

export const _setMemberCache = async (groupId, members) => {
   sessionStorage.setItem(groupId, JSON.stringify(members));
}

export const _getGroupMembersFromCache = async (groupId) => {
   var memberData = JSON.parse(sessionStorage.getItem(groupId));
   if (!memberData) {
      memberData = await _getGroupMembers(groupId);
      _setMemberCache(groupId, memberData);
   }

   return memberData;
}

export const _updateMemberInCache = (groupId, memberId, member, changeType) => {
   var memberData = JSON.parse(sessionStorage.getItem(groupId)) || {};
   switch (changeType) {
      case 'modified':
         memberData[memberId] = member;
         break;
      case 'removed':
         delete memberData[memberId];
         break;
      case 'added':
         memberData[memberId] = member;
         break;
   }

   _setMemberCache(groupId, memberData);
}