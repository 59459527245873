import React, { useState, useEffect } from "react";
import { loggedIn } from "../utils/firebase";
import { _getGroup, _getGroupMembers, _saveNewGroupMember, _getSessions, _getSpecificSession } from "../data/groups";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiCrown, mdiPencil } from '@mdi/js';
import { toggleModal } from "../utils/uiUtils";

export const GroupSession = ({ groupId }) => {
    var location = useLocation();
    const [sessionId, setSessionId] = useState(location.state?.sessionId);
    const [sessionData, setSessionData] = useState();
    const [gameSessions, setGameSessions] = useState();
    const [groupMembers, setGroupMembers] = useState(location.state?.groupMembers);
    const [isLoading, setIsLoading] = useState(true);
    const [winnerStats, setWinnerStats] = useState([]);
    const [editGame, setEditGame] = useState();

    const navigate = useNavigate();

    const init = async () => {
        // Not logged in, go back to home
        if (!loggedIn()) {
            navigate("/");
        }

        // No GroupId, go back to home
        if (!groupId) {
            navigate("/");
        }

        // No members, get them
        if (!groupMembers) {
            var tmpMembers = await _getGroupMembers(groupId);
            setGroupMembers(tmpMembers);
        }

        // Get last 10 sessions
        var tmpSessions = await _getSessions(groupId, 10);
        setGameSessions(tmpSessions);

        // No session Id, use most recent
        if (!sessionId) {
            setSessionId(tmpSessions[0]);
        }

        // Get session data
        var tmpSessionData = await _getSpecificSession(groupId, sessionId);
        setSessionData(tmpSessionData);

        setIsLoading(false);
    }

    const changeSession = async (sessionId) => {
        setIsLoading(true);
        setSessionId(sessionId);
        var tmpSessionData = await _getSpecificSession(groupId, sessionId);
        setSessionData(tmpSessionData);
        setIsLoading(false);
    }

    const editGameInSession = (gameIndex) => {
        navigate("/groupEditGame/" + groupId, { state: { sessionId: sessionId, sessionData: sessionData, gameIndex: gameIndex } })
    }

    useEffect(() => {
        var winners = {};
        if (sessionData) {
            sessionData.games.forEach(game => {
                game.winners?.forEach(winner => {
                    winners[winner] = winners[winner] ? winners[winner] + 1 : 1;
                });
            });

            // Sort winners object by the value of the keys
            const sortedWinners = Object.entries(winners).sort((a, b) => b[1] - a[1]);
            setWinnerStats(sortedWinners);
        }
    }, [sessionData]);

    useEffect(() => {
        init();
    }, []);

    const getTagColor = (index) => {
        switch (index) {
            case 0:
                return "is-warning";
                break;
            case 1:
                return "is-success";
                break;
            case 2:
                return "is-info";
                break;
            default:
                return "is-primary";
                break;
        }
    }

    const updateSessionData = async (newSessionData) => {
        var tmpSessionData = await _getSpecificSession(groupId, sessionId);
        setSessionData(tmpSessionData);
    }


    if (isLoading) {
        return (
            <div className="container">
                <h1>Loading...</h1>
            </div>
        );
    }

    return (
        <>
            <div className="">
                <div className="is-flex is-justify-content-center is-size-4 has-text-weigh-semibold is-underlined" >Gaming on {sessionId}</div>

                <div className="container p-2">
                    <div className="columns">
                        <div className="column is-2-desktop is-12-mobile">
                            <div className="box has-background-info-95 has-wrap-anywhere">
                                <div className="is-size-5 has-text-weight-semibold">
                                    Wins<br />
                                </div>
                                <div>
                                    {winnerStats.map((winner, index) => {
                                        return (
                                            <div key={index} className="columns mt-0 mb-0 pt-0 pb-0 is-mobile">
                                                <div className="column is-2 pt-1 pb-1">
                                                    <span className={"tag " + getTagColor(index)}>
                                                        {winner[1]} 
                                                    </span>
                                                </div>
                                                <div className="column is-6 pt-1 pb-1 ml-3">
                                                    {groupMembers.members[winner[0]]?.displayName}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="column is-8">
                            {sessionData && sessionData.games.map((game, index) => {
                                if (game.isDeleted) {
                                    return null;
                                }
                                
                                return (
                                    <div key={index} className="mb-3 has-border-bottom">
                                        <div className="is-size-5 has-text-weight-semibold">
                                            {game.game} <span className="icon"><Icon path={mdiPencil} size={0.75} color="purple" onClick={() => { editGameInSession(index) }} /></span>
                                        </div>
                                        {game.isCoop && <div>
                                            <span className={"tag is-small " + (game.gameWon ? "is-success" : "is-danger")}>
                                                {game.gameWon ? "Won" : "Lost"}
                                            </span>
                                            <span className={"ml-5 has-text-weight-semibold " + (game.gameScore ? "" : "is-hidden")}> Score: {game.gameScore} </span>
                                        </div>
                                        }
                                        <div className="columns is-multiline is-mobile">
                                            {game.players.sort((a, b) => b.score - a.score).map((player, gameIndex) => {
                                                const playerScore = player.score;
                                                return (
                                                    <div className="column is-6-mobile is-3-desktop no-wrap" key={game + index + gameIndex}>
                                                        {groupMembers.members[player.member]?.displayName} {(playerScore ? ": " + playerScore : "")}
                                                        <Icon path={mdiCrown} size={1} color="DarkGoldenRod" className={"" + (game.winners?.includes(player.member) ? "" : " is-hidden")} />
                                                    </div>

                                                );
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}