import React from 'react';

function Footer() {
    return (
        <>
            <div id="modal-privacy" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Privacy</p>
                        <button className="delete" aria-label="close" onClick={() => { document.getElementById('modal-privacy').classList.remove('is-active') }}></button>
                    </header>
                    <section className="modal-card-body">

                        <div className="is-size-6">
                            <li className="mt-0">When you log in with Google, Yahoo or Microsft
                            </li>
                                <div className="is-list ml-5">
                                    <li>we will pull your name from your profile to display, but you can change this</li>
                                    <li>we don't store or display your email, or any other information from your account</li>
                                </div>
                            <li>We save the following information:</li>
                            <div className="is-list ml-5">
                                <li>The games you played, the date, and how often</li>
                                <li>The player names you entered manually</li>
                                <li>The date you first logged in, and the last date you logged in</li>
                                <li>The rating you give a game</li>
                            </div>
                            <li>The information we do have will never be shared with any third party sites</li>
                            <li>We do not do analytics or tracking, and do not do any form of advertising</li>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button" aria-label="close" onClick={() => { document.getElementById('modal-privacy').classList.remove('is-active') }}>Close</button>
                    </footer>
                </div>
            </div>

            <footer className="has-text-centered is-size-7 mt-auto mb-5 pb-5">
                <div>
                    <div className="is-divider is-left"></div>
                </div>
                <div className="mb-4">
                    <div className="is-link is-clickable is-underlined" onClick={() => { document.getElementById('modal-privacy').classList.add('is-active') }}>
                        Privacy Info
                    </div>
                    <span>Copyright @2024</span>
                    <br />
                </div>

            </footer>
        </>
    );
}


export default Footer;


