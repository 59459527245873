import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { _updatePreviousGame, _deleteGameFromSession } from "../data/groups";
import { _getGroupMembersFromCache } from "../data/localCache";
import { toggleModal } from "../utils/uiUtils";

function GroupEditGame({ groupId }) {
    var location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [sessionId, setSessionId] = useState(location.state?.sessionId);
    const [sessionData, setSessionData] = useState(location.state?.sessionData);
    const [gameIndex, setGameIndex] = useState(location.state?.gameIndex);
    const [gameData, setGameData] = useState();
    const [isCoop, setIsCoop] = useState(false);
    const [gameDate, setGameDate] = useState();
    const [originalGameData, setOriginalGameData] = useState();
    const [groupMembers, setGroupMembers] = useState();

    var navigate = useNavigate();
    useEffect(() => {
        if (sessionId && sessionData && gameIndex >= 0) {
            var tmpDate = new Date(sessionId.replace(/-/g, '/')).toISOString().split('T')[0];
            setGameDate(tmpDate);
            var tmpGameData = sessionData.games[gameIndex];
            setGameData(tmpGameData);
            setOriginalGameData(tmpGameData);
            if (tmpGameData.isCoop) {
                setIsCoop(true);
            }
            setIsLoading(false);
        }

    }, [sessionId, sessionData, gameIndex]);

    useEffect(() => {
        _getGroupMembersFromCache(groupId).then((members) => {
            setGroupMembers(members);
        });
    }, []);

    const updatePlayerScore = (index, value) => {
        const updatedPlayers = gameData.players.map((player, i) => {
            if (i === index) {
                return { ...player, score: value };
            }
            return player;
        });
        setGameData({ ...gameData, players: updatedPlayers });
    };

    const updateWinners = (player) => {
        const winners = gameData?.winners?.includes(player.member)
            ? gameData.winners.filter((winner) => winner !== player.member)
            : [...gameData.winners, player.member];
        setGameData({ ...gameData, winners });
    }

    const deleteGame = () => {
        _deleteGameFromSession(groupId, sessionId, gameIndex).then(() => {
            if (sessionData.games.length <= 1) {
                navigate("/group/" + groupId);
            } else {
                navigate("/groupSession/" + groupId, { state: { sessionId: sessionId } });
            }
        });
    }

    const saveEditedGame = async () => {
        _updatePreviousGame(groupId, sessionId, gameIndex, gameData, originalGameData).then(() => {
            navigate("/groupSession/" + groupId, { state: { sessionId: sessionId } });
        });
    }

    if (isLoading) {

        return <Loading />;
    }

    return (
        <>
            <div>
                <header className="has-text-centered mb-4">
                    <p className="subtitle">Edit Game Play</p>
                </header>
                <section className="modal-card-body">
                    <div id="logGameValidationError" className="is-hidden notification has-background-danger-light has-text-dark has-text-weight-bold">
                        Be sure you have selected a game and date, and either entered scores or checked the winner box for at least one player.
                    </div>
                    <div className="columns is-mobile">
                        <div className="column is-4-desktop is-6-mobile is-6-tablet">
                            <div>Game</div>
                            <input type="text" id="game" className="input is-small" value={gameData.game} onChange={(event) => { setGameData({ ...gameData, game: event.target.value }) }} />


                        </div>
                        <div className="column is-4-desktop is-6-mobile is-6-tablet">
                            <div>Date played</div>
                            <div>
                                <input type="date" id="gameDate" className="input" defaultValue={gameDate}></input>
                            </div>
                        </div>
                    </div>
                    <div className={"columns is-multiline is-mobile " + (isCoop ? "" : "is-hidden")}>
                        <div className="ml-3 has-text-weight-bold is-size-5">Co-op Game</div>
                        <div className="column is-12-desktop is-12-mobile is-12-tablet">
                            <div className="tags has-addons">
                                <span className={"tag is-large is-rounded " + (gameData.gameWon ? " is-primary" : "")} onClick={() => { setGameData({ ...gameData, gameWon: true }) }}>Won</span>
                                <span className={"tag is-large is-rounded " + (gameData.gameWon ? "" : " is-primary")} onClick={() => { setGameData({ ...gameData, gameWon: false }) }}>Lost</span>
                            </div>
                            Optional Score <br />
                            <input type="text" id="coopScore" className="input is-small is-rounded" placeholder="Score" value={gameData.gameScore} onChange={(event) => { setGameData({ ...gameData, gameScore: event.currentTarget.value }) }} ></input>
                        </div>

                    </div>
                    <div>
                        <table className={"table " + (isCoop ? "" : " is-fullwidth")}>
                            <thead>
                                <tr>
                                    <th>Players</th>
                                    <th className={"pl-5 " + (isCoop ? "is-hidden" : "")}>Score</th>
                                    <th className={"pl-5 " + (isCoop ? "is-hidden" : "")}>Winner</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {gameData.players.map((player, index) => (
                                    <tr key={index}>
                                        <td>
                                            {groupMembers[player.member]?.displayName}
                                        </td>
                                        <td className={"pl-5 " + (isCoop ? "is-hidden" : "")}>
                                            <input
                                                type="number"
                                                id={"score-" + index}
                                                className="input is-small text-box-width-short"
                                                value={player.score}
                                                onChange={(event) => {
                                                    updatePlayerScore(index, event.target.value);
                                                }}
                                            />
                                        </td>
                                        <td className={"pl-5 has-text-centered " + (isCoop ? "is-hidden" : "")}>
                                            <label className="checkbox">
                                                <input id={"winner-" + index} type="checkbox" checked={gameData?.winners?.includes(player.member)}
                                                    onChange={() => {
                                                        updateWinners(player);
                                                    }}
                                                />
                                            </label>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>
                                        <button className="button is-primary " onClick={() => { saveEditedGame() }}>Save</button>
                                    </td>
                                    <td>
                                        <button className="button is-light " onClick={() => { navigate("/groupSession/" + groupId, { state: { sessionId: sessionId } }) }}>Cancel</button>
                                    </td>
                                    <td>
                                        <button className="button is-warning " onClick={() => { toggleModal("modal-confirmDelete"); }}>Delete Game</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>

            <div id="modal-confirmDelete" className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head has-text-centered">
                        <p className="modal-card-title">Delete Game?</p>
                        <button className="delete" aria-label="close" onClick={() => { toggleModal('modal-confirmDelete') }}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="is-size-6">
                           Deleting the game is permanent and cannot be undone. Are you sure you want to delete this game?
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={() => { deleteGame() }}>Delete</button>
                        <button className="button" aria-label="close" onClick={() => { toggleModal('modal-confirmDelete') }}>Close</button>
                    </footer>
                </div>
            </div>
        </>
    );


}

export default GroupEditGame;