import { signOut } from 'firebase/auth';
import { auth, db, firebaseObserver, loggedIn } from '../utils/firebase.js';
import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { postSignInCheck } from '../data/users.js';
import { _saveUser } from '../data/users.js';

function AuthenticationButtons({toggleMenu}) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [displayName, setDisplayName] = useState((localStorage.getItem("displayName") || "Click to set"));

    const changeDisplayName = () => {
        var newDisplayName = document.querySelector("#setDisplayName > input").value;
        _saveUser({ displayName: newDisplayName }).then((result) => {
            setDisplayName(newDisplayName);
            toggleMenu();
            localStorage.setItem("displayName", newDisplayName);
            document.getElementById("displayName").classList.remove("is-hidden");
            document.getElementById("setDisplayName").classList.add("is-hidden");
        });
    }

    const cancelChangedDisplayName = () => {
        document.getElementById("displayName").classList.remove("is-hidden");
        document.getElementById("setDisplayName").classList.add("is-hidden");
        document.querySelector("#setDisplayName > input").value = displayName;
    }

    const navigate = useNavigate();

    const logOut = async () => {
        signOut(auth)
            .then(() => {
                localStorage.clear();
                sessionStorage.clear();
                setDisplayName(null);
                toggleMenu();
                navigate('/');
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', isUserLoggedIn => {
            setIsLoggedIn(isUserLoggedIn);
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        if (loggedIn()) {
            setIsLoading(false);
            setIsLoggedIn(true);
        } else {
            setIsLoading(false);
            setIsLoggedIn(false);
        }
    }, []);


    return (
        <>
            {!isLoading &&
                <div className="buttons pt-2 pb-0 mb-0">
                    <h1 className={"mr-4"+ (isLoggedIn ? "" : " is-hidden")} id="displayName" onClick={() => {
                        document.getElementById("displayName").classList.add("is-hidden");
                        document.getElementById("setDisplayName").classList.remove("is-hidden");
                    }} >
                        Logged in as: <span className="has-text-weight-semibold is-underlined is-link is-clickable">{displayName}</span>
                    </h1>
                    <div id="setDisplayName" className="is-hidden">
                        <input type="text" defaultValue={displayName} className="input is-small" /> 
                        <button className="button is-small is-warning mr-1 ml-1" onClick={() => changeDisplayName()}>Save</button>
                        <button className="button is-small is-warning is-outlined mr-5" onClick={() => { cancelChangedDisplayName() }}>Cancel</button>
                    </div>
                    <button className={"button is-hidden-touch is-light mt-0 is-outlined" + (isLoggedIn ? "" : " is-hidden")} onClick={logOut}>Log Out</button>
                    <button className={"button is-dark is-hidden-desktop mt-0 is-outlined" + (isLoggedIn ? "" : " is-hidden")} onClick={logOut}>Log Out</button>
                </div>
            }
        </>
    );
}

export default AuthenticationButtons;
