import { arrayUnion, collection, addDoc, doc, setDoc, getDoc, getDocs, updateDoc, runTransaction, query, limit, whereArrayContains, orderBy, increment } from "firebase/firestore";
import { db, auth } from '../utils/firebase.js';
import { _getGroupMembersFromCache } from "./localCache.js";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const _createGroup = async (groupName) => {
    try {
        const group = {
            groupName: groupName,
            gamesPlayed: {},
            groupAdmins: [auth.currentUser.uid],
            groupMembers: [auth.currentUser.uid],
            owner: auth.currentUser.uid,
            inviteCode: Math.floor(100000 + Math.random() * 900000),
            groupIcon: "meeple_orange"
        }

        var groupMember = {
            uid: auth.currentUser.uid,
            displayName: localStorage.getItem("displayName") || auth.currentUser.displayName
        }

        // Add group to groups collection
        const docRef = await addDoc(collection(db, "groups"), group);
        const userRef = doc(db, 'users', auth.currentUser.uid);
        var userData = { groups: [groupName] };
        var groupId = docRef.id;

        // Add groupMembers as a subcollection
        const groupMembersCollection = collection(db, "groups", groupId, "members");
        await addDoc(groupMembersCollection, groupMember);

        // Add group to user's groups
        const userDoc = await getDoc(userRef);
        const userGroups = userDoc.data().groups || {};
        userGroups[groupId] = groupName;
        userData = { groups: userGroups };

        await updateDoc(userRef, userData);

        return groupId;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const _getGroup = async (groupId) => {
    const groupRef = doc(db, 'groups', groupId);
    const docSnap = await getDoc(groupRef);
    if (docSnap.exists()) {
        return docSnap.data();
    }
}

export const _getGroupMembers = async (groupId) => {
    const groupMembers = { members: {}, owner: "", admins: []};
    const groupRef = doc(db, 'groups', groupId);
    const groupDoc = await getDoc(groupRef);
    const groupData = groupDoc.data();
    groupMembers.admins = groupData.groupAdmins;

    const groupMembersCollection = collection(db, "groups", groupId, "members");
    const querySnapshot = await getDocs(groupMembersCollection);
    querySnapshot.forEach((doc) => {
        groupMembers.members[doc.id] = {
            displayName: doc.data().displayName,
            isOwner: groupData.owner === doc.data().uid,
            inactive: doc.data().inactive
        };
    });

    return groupMembers;
}

export const _getUnclaimedMembers = async (groupId) => {
    const groupMembersCollection = collection(db, "groups", groupId, "members");
    const querySnapshot = await getDocs(groupMembersCollection);
    let groupMembers = [];
    querySnapshot.forEach((doc) => {
        if (!doc.data().uid) {
            var tmpMember = {
                memberId: doc.id,
                displayName: doc.data().displayName
            }

            groupMembers.push(tmpMember);
        }
    });

    return groupMembers;

}

export const _saveNewGroupMember = async (groupId, memberName) => {
    let newMember = {
        displayName: memberName
    }
    const groupMembersCollection = collection(db, "groups", groupId, "members");
    const docRef = await addDoc(groupMembersCollection, newMember);
    const memberId = docRef.id;
    newMember.memberId = memberId;
    return newMember;
}

export const _tryJoinGroup = async (groupId, inviteCode) => {
    const groupRef = doc(db, 'groups', groupId);
    const userUid = auth.currentUser.uid;
    try {
        await setDoc(groupRef, {
            groupMembers: arrayUnion(userUid),
            inviteCode: inviteCode
        }, { merge: true });

        var group = await _getGroup(groupId);
        const groupName = group.groupName;
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await setDoc(userRef, { groups: { [groupId]: groupName } }, { merge: true });
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const _claimMember = async (groupId, memberId, displayName) => {
    const memberRef = doc(db, "groups", groupId, "members", memberId);
    var memberData = { uid: auth.currentUser.uid }
    if (displayName) {
        memberData.displayName = displayName;
    }
    await updateDoc(memberRef, memberData);
}

export const _logGamePlayed = async (groupId, documentId, gameData) => {
    await runTransaction(db, async (transaction) => {
        const playSessionsCollection = collection(db, "groups", groupId, "playSessions");
        const playSessionDocRef = doc(playSessionsCollection, documentId);
        const playSessionDoc = await transaction.get(playSessionDocRef);
        const groupDocRef = doc(db, "groups", groupId);
        const groupDoc = await transaction.get(groupDocRef);

        gameData.isNew = true;
        gameData.game = gameData.game.trim();

        // First add or increment the game playSession with the winners
        if (!playSessionDoc.exists()) {
            await transaction.set(playSessionDocRef, { games: [gameData], playDate: new Date() });
        } else {
            const gamesArray = playSessionDoc.data().games;
            gamesArray.push(gameData);
            await transaction.update(playSessionDocRef, { games: gamesArray });
        }

        // Next, update the gamesPlayed and increment the number.
        const gamesPlayedMap = groupDoc.data().gamesPlayed || {};
        const winners = groupDoc.data().winners || {};
        const memberPlays = groupDoc.data().memberPlayCount || {};

        if (gamesPlayedMap[gameData.game]?.count) {
            gamesPlayedMap[gameData.game].count += 1;
        } else {
            gamesPlayedMap[gameData.game] = { count: 1 };
        }

        for (var i = 0; i < gameData.winners?.length; i++) {
            if (winners[gameData.winners[i]]) {
                winners[gameData.winners[i]] += 1;
            } else {
                winners[gameData.winners[i]] = 1;
            }
        }

        for (var i = 0; i < gameData.players.length; i++) {
            if (memberPlays[gameData.players[i].member]) {
                memberPlays[gameData.players[i].member] += 1;
            } else {
                memberPlays[gameData.players[i].member] = 1;
            }
        };

        await transaction.update(groupDocRef, { gamesPlayed: gamesPlayedMap, winners: winners, memberPlayCount: memberPlays });

        // update the /groups/{groupId/gameSessions/{gameName} with the session the new game can be found in
        // update the /groups/{groupId/gameSessions/{gameName} with the session the new game can be found in
        const gameSessionsRef = doc(db, "groups", groupId, "gameSessions", gameData.game);
        await setDoc(gameSessionsRef, { [documentId]: increment(1) }, { merge: true });
    });
}

function arraysEqualIgnoreOrder(a, b) {
    if (a.length !== b.length) {
        return false;
    }
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    for (let i = 0; i < sortedA.length; i++) {
        if (sortedA[i] !== sortedB[i]) {
            return false;
        }
    }
    return true;
}

export const _updatePreviousGame = async (groupId, sessionId, gameIndex, gameData, oldGameData) => {
    await runTransaction(db, async (transaction) => {
        // First get the /groups/{groupId}/playSessions/{sessionId} and replace the games at {gameIndex} with the new gameData
        const groupRef = doc(db, "groups", groupId);
        const playSessionRef = doc(db, "groups", groupId, "playSessions", sessionId);
        const playSessionDoc = await transaction.get(playSessionRef);
        const playSessionData = playSessionDoc.data();
        playSessionData.games[gameIndex] = gameData;

        // set an isDirty bit for the trigger to know which game to review
        gameData.isDirty = true;

        // old was competitive and new is competitive
        let winnerData = { winners: {} };
        if (!oldGameData.isCoop && !gameData.isCoop) {
            // did the winner change?
            if (!arraysEqualIgnoreOrder(oldGameData.winners, gameData.winners)) {
                // decrement the old winner count
                for (const winner of oldGameData.winners) {
                    winnerData.winners[winner] = increment(-1);
                }

                // increment the new winner count
                for (const winner of gameData.winners) {
                    winnerData.winners[winner] = increment(1);
                }

                await transaction.set(groupRef, winnerData, { merge: true });
            }
        } else if (oldGameData.isCoop && gameData.isCoop) {      // old was coop and new is coop
            delete gameData.winners;
        } else {
            // Not sure how we got here - game should not be able to change from Coop to Competitive (or vice versa)
            return;
        }

        await transaction.update(playSessionRef, { games: playSessionData.games });
    });
}

export const _deleteGameFromSession = async (groupId, sessionId, gameIndex) => {
    await runTransaction(db, async (transaction) => {
        var groupData = { memberPlayCount: {}, gamesPlayed: {} };

        // Trust no one. Get the game
        const playSessionRef = doc(db, "groups", groupId, "playSessions", sessionId);
        const playSessionDoc = await transaction.get(playSessionRef);
        const playSessionData = playSessionDoc.data();
        const gameData = playSessionData.games[gameIndex];

        if (!gameData.isCoop) {
            // find the winners and decrement their wins
            groupData.winners = {};
            for (const winner of gameData.winners) {
                groupData.winners[winner] = increment(-1);
            }
        }

        // find each player and decrement their plays
        for (const player of gameData.players) {
            groupData.memberPlayCount[player.member] = increment(-1);
        }

        // decrement the number of times this game was played
        groupData.gamesPlayed[gameData.game] = { count: increment(-1) };
        transaction.set(doc(db, "groups", groupId), groupData, { merge: true });

        // find the /group/{groupId}/gameSessions/{gameName} and decrement the count
        const gameSessionsRef = doc(db, "groups", groupId, "gameSessions", gameData.game);
        transaction.update(gameSessionsRef, { [sessionId]: increment(-1) });

        // delete the document at /groups/{groupId}/playSessions/{sessionId}/games[gameIndex]
        const gamesArray = playSessionData.games;
        gamesArray[gameIndex].isDeleted = true;
        if (gamesArray.length === 0) {
            transaction.delete(playSessionRef);
        } else {
            transaction.update(playSessionRef, { games: gamesArray });
        }
    });
}

export const _getSessions = async (groupId, queryLimit) => {
    const playSessionsCollection = collection(db, "groups", groupId, "playSessions");
    const playSessionsQuery = await getDocs(query(playSessionsCollection, limit(queryLimit), orderBy("playDate", "desc")));
    const sessions = [];
    playSessionsQuery.forEach((doc) => {
        sessions.push(doc.id);
    });

    return sessions;
}

export const _getSpecificSession = async (groupId, sessionId) => {
    const playSessionDocRef = doc(db, "groups", groupId, "playSessions", sessionId);
    const playSessionDoc = await getDoc(playSessionDocRef);
    var playSessionData = playSessionDoc.data();
    return playSessionData;
}

export const _getGamesPlayed = async (groupId) => {
    const groupRef = doc(db, "groups", groupId);
    const groupDoc = await getDoc(groupRef);
    return groupDoc.data().gamesPlayed;
}

export const _rateGame = async (groupId, game, rating) => {
    const ratingRef = doc(db, "groups", groupId, "ratings", auth.currentUser.uid);
    await setDoc(ratingRef, { [game]: rating }, { merge: true });
}

export const _getGameStats = async (groupId, game) => {
    const gameStatsRef = doc(db, "groups", groupId, "games", game);
    const gameStatsDoc = await getDoc(gameStatsRef);
    var gameStatsData = gameStatsDoc.data();

    if (!gameStatsData) {
        return {};
    }

    return gameStatsData;
}

export const _toggleInactiveUser = async (groupId, memberId, isInactive) => {
    const memberRef = doc(db, "groups", groupId, "members", memberId);
    await updateDoc(memberRef, { inactive: isInactive });
}

export const _getGroupIcon = async (groupId) => {
    const groupIcon = sessionStorage.getItem("groupIcon");
    if (groupIcon) {
        return groupIcon;
    }
    
    const groupRef = doc(db, "groups", groupId);
    const groupDoc = await getDoc(groupRef);
    const groupData = groupDoc.data();
    var iconName = groupData.icon;
    const storage = getStorage();
    const storageRef = ref(storage, `group_icons/light/${iconName}.png`);
    getDownloadURL(storageRef).then((url) => {
        return url;
    }).catch((error) => {
        return "generic_group_icon.png";
    });
}

export const _setGroupIcon = async (groupId, iconName) => {
    const groupRef = doc(db, "groups", groupId);
    await updateDoc(groupRef, { icon: iconName });
    const storage = getStorage();
    var storageRef = ref(storage, `group_icons/light/${iconName}.png`);
    var url = await getDownloadURL(storageRef);
    sessionStorage.setItem("groupIcon", url);
}