import React, { useState, useEffect } from "react";
import { _getSessions } from "../data/groups";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

function GroupSessionList({ groupId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [groupSessions, setGroupSessions] = useState([]);
    const [groupName, setGroupName] = useState("");

    const navigate = useNavigate();
    const init = async () => {
        _getSessions(groupId).then((sessions) => {
            setGroupSessions(sessions);
            setIsLoading(false);
            setGroupName(sessionStorage.getItem("groupName"));
        });
    }

    useEffect(() => {
        init();
    }, []);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div>
                <div className="box has-background-info-95 has-wrap-anywhere">
                    <div className="is-size-4 has-text-weight-semibold">
                        Play Sessions<br />
                    </div>
                    <div className="is-7 mb-2">
                        Tap/click to view full session
                    </div>
                    <div>
                        {groupSessions.map((session, index) => {
                            return (
                                <div key={index} onClick={() => { navigate("/groupSession/" + groupId, { state: { sessionId: session } }); }} className="is-clickable">
                                    {session}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupSessionList;