import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import App from './App';
import Footer from './components/Footer';
import { loader, GroupEditIconPage, GroupPage, JoinGroupPage, GroupSessionPage, RateGamesPage, LogGamesPage, GroupMembersPage, GroupSessionsPage,GameStatsPage, GroupEditGamePage } from './pageDefinitions.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/group/:id",
    element: <GroupPage />,
    loader: loader
  },
  {
    path: "joinGroup/:id",
    element: <JoinGroupPage />,
    loader: loader
  },
  {
    path: "groupSession/:id",
    element: <GroupSessionPage />,
    loader: loader
  },
  {
    path: "rateGames/:id",
    element: <RateGamesPage />,
    loader: loader
  },
  {
    path: "logGame/:id",
    element: <LogGamesPage />,
    loader: loader
  },
  {
    path: "/groupMembers/:id",
    element: <GroupMembersPage />,
    loader: loader
  },
  {
    path: "/groupSessions/:id",
    element: <GroupSessionsPage />,
    loader: loader
  },
  {
    path: "/gameStats/:id",
    element: <GameStatsPage />,
    loader: loader
  },
  {
    path: "/groupEditGame/:id",
    element: <GroupEditGamePage />,
    loader: loader
  },
  {
    path: "/groupEditIcon/:id",
    element: <GroupEditIconPage />,
    loader: loader
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="is-flex-direction-column hero is-fullheight">
      <RouterProvider router={router} />
      <Footer></Footer>
    </div>
  </React.StrictMode>
);

